*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: 0;
  background: transparent;
  border: 0;
  border-style: solid;
  margin: 0;
  padding: 0;
}
ul{
  margin-bottom: unset !important;
}
button {
  cursor: pointer;
  border: none;
}

a {
  text-decoration: none;
  color: var(--color-text) !important;
}
h2 {
  font-weight: 600;
  font-size: 1.75rem;
  opacity: .7;
}

textarea {
  resize: vertical;
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: none;
  transition: background-color 5000s ease-in-out 0s;
}

pre {
  max-height: 30rem;
  box-shadow: $shadow;
  border-radius: 1rem;
  padding: 1rem !important;
  background-color: $dark !important;
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue;
    border-radius: 1rem;
  }
}

blockquote {
  border: $brd;
  border-left: 4px solid #ccc;
  padding: 0.5rem 1rem !important;
  border-radius: $br;
  background-color: $light;
}
