
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-text) !important;
}

.fs-xxsm {
  font-size: $fs-xxsm !important;
}
.fs-xsm {
  font-size: $fs-xsm !important;
}
.fs-sm {
  font-size: $fs-sm !important;
}
.fs-nm {
  font-size: $fs-nm !important;
}
.fs-md {
  font-size: $fs-md !important;
}
.fs-lg {
  font-size: $fs-lg !important;
}
.fs-xl {
  font-size: $fs-xl !important;
}
.fs-xxl {
  font-size: $fs-xxl !important;
}
.fs-head {
  font-size: $fs-head !important;
}

.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.underline {
  text-decoration: underline;
}
.lthrough {
  text-decoration: line-through;
}
.ta-center {
  text-align: center;
}
.ta-left {
  text-align: left;
}

.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
