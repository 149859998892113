.ant-table-light {
  background-color: white;
  color: #333333;

  /* Add more styles for ant-table-light */
}

.ant-table-dark {
  background-color: #262626;
  color: #ffffff;
  /* Add more styles for ant-table-dark */
}

.ant-table-dark .ant-table-tbody > tr > td {
  background-color: #f5f5f5;
  color: #333333;
}

.ant-table-dark .ant-table-tbody > tr > td {
  background-color: #262626;
  color: #ffffff;
}

.ant-table-dark .ant-table-table-cell:first-child {
  background-color: #232323;
  color: #fff;
}
.ant-table-dark .ant-table-table-cell:last-child {
  background-color: #232323;
  color: #fff;
}

.ant-table-light .ant-table-tbody > tr > td {
  background-color: #f5f5f5;
  color: #333333;
}

.ant-table-light .ant-table-tbody > tr > td {
  background-color: #f5f5f5;
  color: #333333;
}

.ant-table-light .ant-table-table-cell:first-child {
  background-color: white;
  color: #333333;
}

.ant-table-light .ant-table-table-cell:last-child {
  background-color: white;
  color: #333333;
}

.reports_container tr td:hover {
  background-color: #b2b2b2 !important;
}

.ant-progress-dark .ant-progress-text {
  color: #f5f5f5;
}
.ant-progress-light .ant-progress-text {
  color: #333333;
}
