$primary: #3e9fbe;
$orange: #f44336;
$facebook: #507fc1;
$extra-light-gray: #ededed;
$light-gray: #fafafa;
$semi-dark-gray: #d1d8dd;
$dark-gray: #d2d2d2;
$blue: #ffd51c;
$dark-blue: #3c4858;
$gray: #8898aa;
$fb: #3b5999;
$twitter: #1da1f2;
$insta: #ea4c89;
$light: #f5f5f5;
$red: #f72848;
$yt: #ef462c;
$yellow: #ffd51c;
$green: #12d469;
$black: #37352f;
$dark: #31363e;
$patreon: rgb(232, 91, 70);
$purple: #d91c5c;
$modal-bg: #31363e;
$avatar-dark-blue: #7daee9;
$avatar-light-blue: #7de9e9;
$avatar-pink: #e97dbf;
$avatar-purple: #e97da2;
$avatar-orange: #e9c87d;
$avatar-red: #e97d7d;
$bg: #f7f7f7;
$bg-light: #fdfdfd;
$circle-red: #f94456;
$circle-yellow: #ffdb79;
$circle-blue: #79a6ff;
$search-pink: #ea135c;
$wrong-pink: #f00544;
$correct-green: #00d48b;

$fs-xxsm: 9px;
$fs-xsm: 12px;
$fs-sm: 14px;
$fs-nm: 1rem;
$fs-md: 1.1rem;
$fs-lg: 1.25rem;
$fs-xl: 1.5rem;
$fs-xxl: 30px;
$fs-head: 3rem;

$brd: 1px solid #d9d9d9;
$shadow: 0 1px 3px rgba(50, 50, 50, 0.05), 0 5px 15px rgba(0, 0, 0, 0.05) !important;
$shadow-sm: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$br: 0.5rem;
$br-sm: 8px;
$br-xsm: 4px;
