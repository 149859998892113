@import "./variables.scss";

.primary {
  color: $primary;
}
.orange {
  color: $orange;
}
.extra-light-gray {
  color: $extra-light-gray;
}
.light-gray {
  color: $light-gray;
}
.semi-dark-gray {
  color: $semi-dark-gray;
}
.dark-gray {
  color: $dark-gray;
}
.blue {
  color: $blue;
}
.gray {
  color: $gray;
}
.dark-blue {
  color: $dark-blue;
}
.red {
  color: $red !important;
}
.white {
  color: white !important;
}
.yellow {
  color: $yellow;
}
.green {
  color: $green;
}
.black {
  color: $black;
}
.dark {
  color: $dark;
}
.fb {
  color: $fb;
}
.yt {
  color: $yt;
}
.twitter {
  color: $twitter;
}
.patreon {
  color: $patreon;
}
.insta {
  color: $insta;
}
.bg{
background-color: var(--bg-panel);
}
.bg-blue {
  background-color: $blue;
}
.bg-gray {
  background-color: $gray;
}
.bg-light {
  background-color: $light;
}
.bg-red {
  background-color: $red;
}
.bg-green {
  background-color: $green;
}
.bg-white {
  background-color: white;
}
.bg-avatar-dark-blue {
  background-color: $avatar-dark-blue;
}
.bg-avatar-light-blue {
  background-color: $avatar-light-blue;
}
.bg-avatar-pink {
  background-color: $avatar-pink;
}
.bg-avatar-purple {
  background-color: $avatar-purple;
}
.bg-avatar-orange {
  background-color: $avatar-orange;
}
.bg-avatar-red {
  background-color: $avatar-red;
}
.bg-wrong-pink {
  background-color: $wrong-pink;
}
.bg-correct-green {
  background-color: $correct-green;
}

.flex {
  display: flex;
}
.flex-reverse {
  flex-direction: row-reverse;
}
.al-start {
  align-items: flex-start !important;
}
.al-end {
  align-items: flex-end;
}
.al-center {
  align-items: center;
}
.al-stretch {
  align-items: stretch;
}
.column {
  display: flex;
  flex-direction: column;
}
.jst-start {
  justify-content: flex-start !important;
}
.jst-center {
  justify-content: center;
}
.jst-between {
  justify-content: space-between !important;
}
.al-self-start {
  align-self: flex-start;
}
.al-self-end {
  align-self: flex-end;
}
.wrap {
  flex-wrap: wrap;
}

.input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--color-light);

}
.input {
  background-color: var(--bg-panel) !important;
  border-radius: 5px;
  box-shadow: var(--shadow-button);
  height: 2rem;
  margin-left: 1rem;
  
  img,
  svg {
    font-size: 0.9rem;
    margin-left: 1rem;
  }
  input,
  textarea {
    color: var(--color-text);

    padding: 1rem;
    &::placeholder {
      font-weight: 400;
      color: var(--color-light_2);
      // color: $dark-gray;
    }
    width: 100%;
  }
  textarea {
    height: 5rem;
  }
}
.input-error {
  border: 1px solid $red !important;
}

.input-success {
  border: 1px solid $green !important;
}
.read-only {
  opacity: .3;
}
.char-counter {
  align-self: flex-end;
  margin-top: 0.5rem;
  color: #c6c6c6;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-light);
}
.btn-basic{
  background-color: var(--bg-panel);
}

.btn-basic,
.btn-blue,
.btn-red,
.btn-pink,
.btn-facebook,
.btn-orange,
.btn-primary,
.btn-purple,
.btn-dark {
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
}
.btn-basic {
  // border: 1px solid var(--border-color);
}
.btn-light {
  background-color: $light;
  color: #bebebe;
}
.btn-blue {
  border: none;
  background-color: $blue;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-red {
  border: none;
  background-color: $red;
  color: white;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-primary {
  background-color: rgba(96, 168, 221, 1);
  color: white;
}
.btn-facebook {
  background-color: $facebook;
  color: white;
}
.btn-orange {
  background-color: $orange;
  color: white;
}
.btn-purple {
  background-color: $purple;
  color: white;
}
.btn-pink {
  border: none;
  background-color: $insta;
  color: white;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-dark {
  background-color: $dark;
  color: white;
}
.brd {
  border: 1px solid #d9d9d9;
}
.brd-lg {
  border: 0.15rem solid #d9d9d9;
}
.brd-blue {
  border-color: $blue;
}
.brd-red {
  border-color: $red;
}
.brd-green {
  border-color: $green;
}

.active-link {
  &,
  svg {
    fill: $dark !important;
  }
  font-weight: 500;
}

.shadow {
  box-shadow: $shadow;
}
.shadow-sm {
  box-shadow: $shadow-sm;
}
.shadow-pink {
  box-shadow: 0 7px 15px rgba(#d91c5c, 0.15);
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  width: 100%;
  border-top: 0.0625rem solid #f2f2f2;
}

.w-100 {
  width: 100%;
}

.br-xsm {
  border-radius: $br-xsm;
}
.br-sm {
  border-radius: $br-sm;
}
.br {
  border-radius: $br;
}
.br-50 {
  border-radius: 50%;
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

[type="file"] + label {
  color: white;
  cursor: pointer;
}

[type="file"]:focus + label,
[type="file"] + label:hover {
}

[type="file"]:focus + label {
  outline: none;
  border: 1px solid $blue;
}

.loading-circle {
  border: 0.4rem solid white;
  border-radius: 50%;
  border-top: 0.4rem solid #313842;
  width: 1.75rem;
  height: 1.75rem;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  opacity: 1;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-align: left;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #f5f5f5;
    padding: 0.25rem;
    svg {
      color: $blue;
    }
  }
  span {
    width: calc(100% - 3rem);
  }
  &:hover {
    div {
      background-color: rgba($blue, 0.5);
    }
    svg {
      color: white;
    }
  }
}
.bg-shadow {
  background-color: rgba(black, 0.5);
  position: absolute;
  width: 85%;
  height: 100%;
  top: 30px;
  left: 7.5%;
  z-index: -1;
  -webkit-filter: blur(18px);
  filter: blur(18px);
}
.profile-picture-change {
  position: absolute;
  right: 1rem;
  bottom: -0.5rem;
}
.fit-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#d1d8dd, 0.31);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #d1d8dd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffedf3;
  &:before {
    background-color: #d91c5c;
    box-shadow: 0px 0px 5px rgba(#d91c5c, 0.5);
  }
}

input:checked + .slider:before {
  top: 50%;
  -webkit-transform: translateX(24px) translateY(-50%);
  -ms-transform: translateX(24px) translateY(-50%);
  transform: translateX(24px) translateY(-50%);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type="range"]::-webkit-slider-thumb {
  cursor: pointer;
}

.ios-control-disable {
  video::-webkit-media-controls {
    display: none !important;
    -webkit-appearance: none;
  }

  *::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
  }

  *::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }

  *::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(96, 168, 221, 1);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(96, 168, 221, 1);
}
