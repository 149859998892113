@media screen and (min-width: 1300px) {
  .modal_add_leave {
    width: 25%;
  }

  // .number_row div {
  //   margin: 0;
  //   number {
  //     font-weight: 500;
  //     font-size: 3rem;
  //   }
  // }
}

@media screen and (max-width: 1200px) {
  .cost-body {
    flex-direction: column !important;
  }
  .modal_add_employee {
    max-width: 40%;
  }

  .modal_add_leave {
    width: 30%;
  }
  .pano_body {
    flex-direction: column;
  }

  .pano_body_right_top {
    margin: 0 0 1rem 0 !important;
    min-height: 20vh;
  }

  .pano_body_right_bottom {
    margin: 0 0 1rem 0 !important;
    min-height: 20vh;
  }
}

@media screen and (max-width: 1100px) {
  .modal_add_employee {
    max-width: 50%;
  }

  .modal_add_leave {
    min-width: 30%;
  }

  .grid_card {
    width: calc((100% - 2rem) / 2);
    margin: 0 1rem 1rem 0;
  }
}

@media screen and (max-width: 950px) {
  .project_container {
    flex-direction: column;
  }

  .modal_add_employee {
    max-width: 70%;
  }

  .modal_add_leave {
    min-width: 50%;
  }

  .employee_detail_content_right {
    padding: 2rem 1rem 2rem 1rem;
  }

  .employee_detail_content_left {
    padding: 2rem 1rem 2rem 1rem;
  }

  .employee_detail_task {
    padding: 1rem 0.5rem 1rem 0.5rem;
  }

  .employee_detail_task {
    width: 100%;
    background-color: var(--bg-panel);
    border-radius: 1rem;
    box-shadow: var(--shadow);
    text-align: center;
    position: relative;
    padding: 1rem;

    h4 {
      margin-bottom: 1rem;
    }

    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      li {
        min-height: 4rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }
    }

    .date {
      color: var(--color-text);
      font-size: 0.75rem;
      position: absolute;
      left: 60%;
      display: inline-block;
      // right: 5%;
    }

    .color {
      min-height: 3rem;
      min-width: 0.5rem;
      position: absolute;
      left: 50%;
    }

    .desc {
      position: absolute;
      right: 55%;
      left: 10%;
      color: var(--color-light);
    }
  }
}

@media screen and (max-width: 800px) {
  .diaryForm {
    height: 95%;
    overflow-y: scroll;
    height: 95%;
    display: inline-block;
    width: 100%;
  }
  .description_placeholder {
    min-height: 50px;
  }
  .modal_scheduler {
    height: 90%;
  }
  .modal_gantt {
    height: 90%;
  }

  .project_step_form_container {
    overflow-y: scroll;
    height: 95%;
    display: inline-block;
    width: 100%;
  }

  .filters {
    flex-direction: column;
    padding-right: unset !important;
    .filter_group {
      min-width: 100%;
      div {
        min-width: 100%;
      }
    }
  }
  .number_row div {
    margin: 0;
    number {
      font-weight: 500;
      font-size: 3rem;
    }
    span {
      padding: unset;
      position: absolute !important;
      top: 0.5rem !important;
      font-weight: 400 !important;
      // font-size: .5rem !important;
      margin-left: -0.5rem;
    }
  }
  .number_row {
    div:nth-child(1) {
      margin-right: 1rem;
    }
  }
  .pano_header div:first-child span {
    font-size: $fs-md;
    width: 100%;
    // text-align: center;
  }
  .pano_header div:last-child span:first-child {
    font-size: 2rem;
  }
  .pano_header div:last-child span:last-child {
    // font-size: $fs-md;
  }
  .pano_header div:last-child {
    justify-content: unset;
    align-items: unset;
  }
  .pano_header div:first-child span:last-child {
    display: none;
    align-items: unset;
  }
  .project_cost_header_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    button {
      width: 100%;
      margin: 0.5rem 0 0.5rem 0 !important;
    }
  }

  .pano_header {
    display: unset;
  }
  .switch_container {
    width: 100%;
    margin: 0.5rem 0 0.5rem 0 !important;
  }

  .project_cost_container {
    section:nth-child(2) {
      display: unset;

      div {
        width: calc(100%);
        height: calc(100%);
      }

      div:nth-child(1) {
        border-right: 1px solid var(--border-color2);
        border: unset;
      }
    }
  }
  .pano_body .pano_body_left .pano_body_left_top .pano_body_left_top_pano_panel {
    margin: 1rem 0 0 0;
    min-height: 30vh;
    span {
      font-size: 0.75rem;
    }
  }
  .pano_body .pano_body_left .pano_body_left_top .pano_body_left_top_pano_panel:last-child {
    table {
      height: auto !important;
    }
  }
  .pano_body .pano_body_left .pano_body_left_bottom {
    .reports_group {
      min-height: 20vh;
    }
    flex: 1 !important;
    div {
      canvas {
        margin: 0.5rem 0 0.5rem 0.15rem;
      }
    }
  }
  .pano_body_left_top {
    flex-direction: column;
    flex: 1 !important;
  }
  .customer_detail_information_container {
    height: unset;
  }

  .customer_detail_information {
    float: unset;
    height: unset;
    width: 100%;
    padding: 1rem;
  }

  .modal_add_employee {
    max-width: 90%;
  }

  .grid_card {
    width: calc((100%) / 1);
    margin: 0 0 1rem 0;
  }

  .modal_add_leave {
    min-width: 50%;
  }
}

@media screen and(max-width: 600px) {
  .number_row div{
    min-width: unset;
  }
  .background_image_uploader{
    top: 7.75rem;
    width: 2rem;
    opacity: .7;
    padding: .25rem;
    changeBackgroundImagetext{
      display: none;
    }
  }
  .rbc-calendar {
    width: 250%;
    height: 700px;
  }
  .scheduler_container {
    padding-top: 2rem;
    width: 100%;
    overflow-x: auto;
  }
  .logo {
    svg {
      width: 2.5rem;
    }
  }
  .logo_when_open {
    height: unset;
    padding: 1.5rem 3rem 0 3rem;
    svg {
      //  width: 2rem;
    }
  }
  .login_image {
    img {
      display: none;
    }
  }
  .term_of_use_detail {
    padding: 1rem 0.5rem;
    max-width: 95%;
  }
  .termofusemodal {
    overflow-x: none;
  }
  .login_title {
    visibility: hidden;
    display: none;
    h1 {
      visibility: hidden;
    }
    p {
      visibility: hidden;
    }
  }
  .login_form {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
  }
  .login_form_inside {
    position: relative;
    justify-content: space-between;
    .input-container {
      margin-bottom: 0rem;
    }
    .input_header {
      display: none;
    }
    // bottom: 10%;
    max-width: unset;
    // line-height: 1.5rem;
    h2 {
      font-size: 1.75rem;
    }
    p {
      font-size: $fs-md;
    }
    right: unset;
    top: unset;
  }
  .anonymous_container {
    position: fixed;
    left: 10%;
    top: 40%;
    color: var(--color-light);
    h1 {
      font-size: 2.5rem;
      line-height: 2rem;
    }
    p {
      font-size: $fs-md;
    }
  }

  .workorder_detail_right {
    width: 100%;
    margin-top: 1rem;
  }

  .project_group .project_group_container {
    width: 100%;
  }

  .project_view {
    width: 100%;
  }

  .authModalContainer {
    width: 100%;
  }

  .customer_detail_profile {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .customer_detail_profile_information {
    margin-top: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-left: unset;
  }

  .custemer_detail_container {
    height: unset;
    padding: 1rem;
    margin-top: 2rem;
  }

  .grid_card_information_container {
    margin-top: 1rem;
  }

  .grid_card_profile_img_container {
    justify-content: center;
  }

  .grid_card_settings {
    padding: unset;
  }
  .headerandcontent {
    left: 3rem;
    width: calc(100% - 3rem);
    padding: unset;
    // height: 100%;
  }
  .left_menu {
    width: 3rem;
    // overflow-y: auto;
    ul:not(.left_sub_menu) {
      height: calc(100% - 6rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      li {
        padding: unset;
        flex: 1;
      }
    }
  }
  .left_menu_open {
    width: 14rem;
  }
  .left_menu li:not(.left_sub_menu_item) {
    overflow-y: auto;
  }

  .modal_add_leave {
    min-width: 70%;
  }

  .table_properties .btn {
    margin-left: unset;
    margin-bottom: 1rem;
  }

  .notification_container {
    width: calc(100% - 2rem);
    right: 1rem;
    left: 1rem;
  }

  .header {
    box-shadow: var(--shadow-bottom-right);
    // width: calc(100% - 4rem);
    // left: 4rem;
    height: 3.5rem;
  }

  .header_left h2 {
    font-size: $fs-md;
    padding-left: 1rem;
  }

  .header_right {
    padding-right: 1rem;

    .profile_right {
      div {
        display: none;
      }

      img {
        width: 2rem;
        height: 2rem;
      }
    }

    svg {
      width: 1rem;
    }
  }

  .modal_add_employee {
    max-width: 90%;
  }

  .grid_card {
    width: calc((100%) / 1);
    margin: 0 0 1rem 0;
  }

  .content {
    padding: 0 1rem 0.5rem 1rem;
    min-height: calc(100% - 3.5rem);
    position: relative;
    overflow-x: hidden;
    // top: 3.75rem;

    .table {
      width: 100%;
      overflow-x: auto;
      margin-bottom: 1rem;
    }
  }

  .table_properties {
    flex-direction: column;
    padding-top: 2rem;

    .input-container {
      margin-bottom: 1rem;
    }

    .input {
      margin-left: unset;
    }
  }

  .breadcrumb {
    font-size: 0.75rem;
    left: 1rem;

    ul {
      li {
        img {
          width: 0.65rem;
        }
      }
    }
  }

  .employee_detail_profile {
    width: 100%;
  }

  .profile_detail {
    width: 100%;

    .css-nakgy8-TimeKeeper {
      left: 45px;
      margin-top: 0.2rem;
    }
  }

  .workorder_detail {
    width: 100%;
  }

  .employee_detail_container {
    flex-direction: column;
  }

  .employee_detail_right {
    width: 100%;
    padding: unset;
    margin-top: 3rem;
    // overflow-x: scroll;
    position: relative;
  }

  .employee_detail_content {
    margin: 0;
    width: 100%;

    .personal {
      flex-direction: column;
    }

    .table {
      overflow-x: scroll;
    }
  }

  .employee_detail_content_right {
    padding: 0 1rem 2rem 1rem;
  }

  .employee_detail_content_left {
    padding: 2rem 1rem 0 1rem;
  }

  .input-container {
    margin-bottom: 1rem;
  }

  .gender {
    margin: 1rem 0 1rem 0;
  }

  .employee_detail_profile {
    .employee_detail_items {
      margin-bottom: 1rem;
    }
  }

  .meeting_detail_profile {
    width: 100%;
  }

  .meeting_detail_info {
    .meeting_info {
      flex-direction: column;
    }

    .meeting_detail_header {
      padding: 0.7rem 1rem 0rem 1rem;
    }
  }

  .project_group {
    .meeting_group_container {
      margin: 0rem;
    }
  }

  .department_container {
    margin-right: 0rem !important;
  }

  .reports_header {
    flex-direction: column;

    .header_right {
      width: 100% !important;
      flex-direction: column;
    }
    .header_left {
      width: 100% !important;
      flex-direction: column;
      margin-right: 0 !important;
      .input-container {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }

  .report_chart_container {
    canvas {
      width: 300px !important;
      height: 300px !important;
    }
  }

  .reports_container {
    td {
      span {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1368px) {
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 450px) {
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 384px) {
}
