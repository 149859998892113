* {
  @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
  font-family: "Comfortaa", cursive;
}

html {
  --antd-wave-shadow-color: rgba(96, 168, 221, 1) !important;
  --bg: #fff;
  --bg-transparent: rgba(250, 250, 250, 0.8);
  --bg-panel: #fff;
  --bg-modal: rgba(0, 0, 0, 0.7);
  --bg-danger: rgba(232, 63, 64, 1);
  --color-text: #222222;
  --color-text-reverse: #eee;
  --color-light: #5d5d5d;
  --color-light_2: #aaa;
  --color-light_3: #b1b1b129;
  --color-sky: #0f76bb;
  --color-sky_2: #4ca4e0;
  --color-sky_3: #1f96e6;
  --color-orange: #f9b547;
  --color-red: #f74a4f;
  --shadow-bottom-right: 10px 1px 15px rgba(50, 50, 50, 0.05);
  --shadow-bottom: 0px 5px 5px -1px rgba(0, 0, 0, 0.1);
  --shadow-right: 1px 1px 5px rgba(75, 75, 75, 0.4);
  --shadow: 0px 3px 13px rgba(75, 75, 75, 0.15);
  --shadow-agenda: 0px 0px 3px 0px rgba(75, 75, 75, 0.15);
  --shadow-button: 0px 1px 2px rgba(50, 50, 50, 0.2);
  --shadow-icon: 1px 1px 3px rgba(50, 50, 50, 0.8);
  --shadow-card: 1px 1px 10px rgba(50, 50, 50, 0.1);
  --border-color: #efefef;
  --primary-bg-color: rgba(96, 168, 221, 0.4);
  --primary: rgba(96, 168, 221, 1);
  --secondary-bg-color: rgba(247, 202, 49, 0.2);
  --secondary: rgba(247, 202, 49, 1);
  --border-color2: #b6b6b68c;
  --file-upload-area: #f0f2f8;

  --bg-21: #dc3545;
  --bg-back-21: #dc354540;
  --bg-13: #ffc107;
  --bg-back-13: #ffc10740;
  --bg-8: #28a745;
  --bg-back-8: #28a74540;
  --bg-5: #17a2b8;
  --bg-back-5: #17a2b840;
  --bg-3: #007bff;
  --bg-back-3: #007bff40;
  --bg-2: #6c757d;
  --bg-back-2: #6c757d40;
  --bg-1: #343a40;
  --bg-back-1: #343a4040;
}

html[data-theme="dark"] {
  --antd-wave-shadow-color: rgba(96, 168, 221, 1) !important;
  --bg: #232323;
  --bg-panel: #2f2f2f;
  --bg-transparent: rgba(50, 50, 50, 0.8);
  --bg-modal: rgba(255, 255, 255, 0.5);
  --bg-danger: rgba(232, 63, 64, 1);
  --color-text: #e5e5e5;
  --color-text-reverse: #222222;
  --color-light: #9f9f9f;
  --color-light_2: #555;
  --shadow-agenda: 0px 0px 3px 0px rgba(150, 150, 150, 0.25);
  --shadow-bottom-right: 10px 1px 15px rgba(22, 22, 22, 1) !important;
  --shadow-bottom: 0px 5px 5px -1px rgba(0, 0, 0, 0.1);
  --shadow-right: 1px 1px 15px rgba(22, 22, 22, 1) !important;
  --shadow: 1px 1px 25px rgba(22, 22, 22, 1);
  --shadow-card: 1px 1px 10px rgba(22, 22, 22, 0.1);
  --shadow-button: 0px 1px 3px rgba(200, 200, 200, 0.3);
  --shadow-icon: 1px 1px 3px rgba(200, 200, 200, 0.3);
  --primary-bg-color: rgba(96, 168, 221, 0.1);
  --primary: rgba(96, 168, 221, 1);
  --secondary-bg-color: rgba(247, 202, 49, 0.2);
  --secondary: rgba(247, 202, 49, 1);
  --border-color: rgba(200, 203, 203, 0.1);
  --border-color2: #ffffff2e;
  --file-upload-area: rgb(70, 70, 70);
}

body {
  background-color: var(--bg) !important;
  // padding: 1rem;
}

.layout {
  height: calc(100vh);
  width: calc(100%);
}

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

svg[role="img"] {
  width: 100%;
}

label[title="check"] {
  cursor: pointer;
  // text-indent: -9999px;
  width: 36px;
  height: 20px;
  background: grey;
  float: left;
  border-radius: 15px;
  position: relative;
}

.table_properties {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  img {
    cursor: pointer;
  }

  .btn {
    box-shadow: var(--shadow-button);
    margin-left: 1rem;
  }

  .filters {
    display: flex;
    width: 100%;
    padding-right: 2rem;
  }
  .filter_group {
    display: flex;
    margin: 1rem 1rem 1rem 0;
    div {
      min-width: 200px;
      max-width: 25%;
    }
  }
}

label[title="check"]:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  background: var(--primary);
}

input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label[title="check"]:active:after {
  width: 45px;
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 500ms !important;
  transition-delay: 0 !important;
}
.headerandcontent {
  position: relative;
  left: 4rem;
  width: calc(100% - 4rem);
  height: 100vh;
  padding: 0rem 1.5rem;
}

.headerandcontent_left_width {
  left: 18.5rem;
  width: calc(100% - 18.5rem);
}

.left_menu {
  z-index: 3;
  cursor: pointer;
  position: fixed;
  font-size: $fs-sm;
  font-weight: 400;
  width: 4rem;
  height: 100%;
  -moz-box-shadow: var(--shadow-right);
  -webkit-box-shadow: var(--shadow-right);
  box-shadow: var(--shadow-right);
  background-color: var(--bg-panel);

  ul:not(.left_sub_menu) {
    padding-top: 0.5rem;
  }

  li:not(.left_sub_menu_item) {
    padding: 1rem;
    display: flex;
    justify-content: center;
  }

  li:hover {
    background-color: var(--primary-bg-color);
    border-right: 0.2rem solid var(--primary);

    span {
      color: var(--primary) !important;
    }
  }

  .left_menu_base {
    // width: 100%;
    // padding-right: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;

    .chevron_open {
      svg {
        transform: rotate(180deg);
      }
    }

    li {
      background-color: unset;
    }
  }

  .left_menu_active {
    // background-color: var(--primary-bg-color);
    border-right: 0.2rem solid var(--primary);

    span {
      color: var(--primary) !important;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
  }

  svg:not(.logo svg) {
    width: 1rem;
    // opacity: 0.5;
  }

  span {
    display: none;
    opacity: 0.8;
  }
}

.logo {
  padding: 0.75rem 0 0 0.25rem;
  display: flex;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 3.25rem;
    margin-right: unset;
  }
}

.logo_when_open {
  justify-content: left;
  // padding-left: 4rem;
  // padding-top: 2.5rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  svg {
    flex: 1;
  }

  // svg {
  //   width: 10.25rem;
  //   margin-right: unset;
  // }
}

.left-menu,
.left_menu_open {
  width: 18.5rem;

  li {
    justify-content: left;
  }

  .left_menu_base {
    // padding: 0.3rem 0.3rem 0.3rem 1.5rem;
    cursor: pointer;
    width: 100%;
    padding: 0 0 0 1rem;
    justify-content: left;
    align-items: center;

    svg {
      margin-right: 1rem;
    }

    span {
      display: unset;
    }

    li {
      background-color: unset;
    }
  }

  li:hover {
    // background-color: var(--primary-bg-color);
    border-right: 0.2rem solid var(--primary);

    span {
      color: var(--primary) !important;
    }

    svg {
      color: var(--primary) !important;
    }
  }

  .left_menu_active {
    // background-color: var(--primary-bg-color);
    border-right: 0.2rem solid var(--primary);

    span {
      color: var(--primary) !important;
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.header {
  border-bottom: 0.001rem solid var(--border-color);
  background-color: var(--bg);
  color: var(--color-light) !important;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 5rem;
  // z-index: 2;
  // visibility: hidden;
  // -moz-box-shadow: var(--shadow-bottom-right);
  // -webkit-box-shadow: var(--shadow-bottom-right);
  // box-shadow: var(--shadow-bottom-right);
}

// .header_left_margin {
//   left: 16rem;
//   width: calc(100% - 18rem);
// }

.header_left {
  // width: 50%;
  flex: 2;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  // padding: 0 0 0.5rem 0;

  h2 {
    font-size: $fs-xl;
  }
}

.header_right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // width: 50%;
  height: 100%;
  flex: 1;
  svg {
    margin-right: 1rem;
    width: 1.25rem;
  }
}

.button {
  box-shadow: var(--shadow-button);
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 5px;
  height: 2rem;
  margin-left: 1rem;
  background-color: var(--bg-panel);
}

.content {
  width: 100%;
  height: auto;
  min-height: calc(100% - 5rem);
  overflow-y: auto;
  float: left;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2rem 0 0 0;
  // background-color: rgb(245, 247, 250);
}
.profile_list_img {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  float: right;
  box-shadow: var(--shadow-button);
}

.settings_icons {
  width: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.button_icon {
  width: 0.85rem;
}

.right {
  float: right;
}

.myspancolor {
  color: blue;
}

.request_form_select {
  width: 480px;
  margin-left: 8px;
  padding-top: 7px;
}

.myselectItem {
  //  margin-left:8px!important;
  //  width:97%;
  width: 480px;
  margin: -2px !important;
  font-size: 15px !important;
  line-height: 10px !important;
  .ant-select-selector {
    border-radius: 0.5rem !important;
  }
}

.selectItemError {
  border-color: red;
  border-width: 1px;
  margin-left: 8px !important;
  width: 97%;
}

.selectItem {
  width: 480px;
  margin-left: 8px;
  padding-top: 7px;
  padding: 0.9rem;
}

// .mytable {
//   width: 100%;
//   border-collapse: collapse;
//   padding: 10rem;
//   margin-bottom: 1rem;
//   color: var(--color-text);
//   margin-top: 20px;
//   border-radius: 0.5rem;
//   box-shadow: var(--shadow);
//   text-align: left;
//   overflow-y: auto;
//   tbody {
//     box-shadow: none;
//   }
// }

table {
  width: 100%;
  border-collapse: collapse;
  padding: 10rem;
  margin-bottom: 1rem;
  color: var(--color-text);

  thead {
    svg {
      width: 0.65rem;
      margin-left: 1rem;
    }
  }

  tbody {
    border-radius: 0.5rem;
    // box-shadow: var(--shadow);
    text-align: left;
    overflow-y: auto;

    .row {
      white-space: nowrap;
      border-bottom: 0.1rem solid var(--border-color);
      font-size: small;

      svg {
        width: 1rem;
        margin-right: 1rem;
      }
    }

    .row:last-child {
      border-bottom: unset;
    }

    .row:hover {
      box-shadow: var(--shadow);
      cursor: pointer;
    }
  }

  th {
    margin: 0;
    padding: 1rem;
    color: var(--color-text);
    text-align: left;
    // opacity: 0.5;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;

    img {
      width: 0.75rem;
      padding-left: 0.25rem;
    }
  }

  td {
    height: 3rem;
    margin: 0;
    padding: 0.25rem 0 0.25rem 1rem;
    text-align: left;
  }
}

.breadcrumb {
  position: absolute;
  top: 0.5rem;

  ul {
    list-style-type: none;
    display: flex;
    align-self: left;
    flex-wrap: nowrap;

    li {
      margin-right: 0.25rem;
      display: flex;

      svg {
        width: 0.3rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: -0.1rem;
      }
    }
  }
}

.pagination {
  // position: absolute;
  // margin-top: 1rem;

  svg {
    width: 20%;
  }

  ul {
    list-style-type: none;
    display: flex;
    align-self: left;
    flex-wrap: nowrap;

    li {
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
      box-shadow: var(--shadow);
      color: var(--color-text);
      font-size: small;
      cursor: pointer;
      border-radius: 0.3rem;
      background-color: var(--bg-panel);
    }

    li:hover {
      color: var(--color-text);
      background-color: var(--primary);
    }

    .active {
      background-color: var(--primary);
      color: var(--color-text);
    }
  }
}

.profile_right {
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  h3 {
    font-weight: 400;
    font-size: 0.9rem;
  }

  span {
    font-size: 0.65rem;
    color: var(--color-light);
  }

  img {
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
  }

  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 0.75rem;
    text-align: right;
  }
}

.grid_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  height: 100%;
  padding-top: 0.1rem;
}

.grid_card {
  background-color: var(--bg-panel);
  height: 8.5rem;
  width: calc((100% - 6rem) / 3);
  margin: 1rem 1rem 0.1rem 1rem;
  border-radius: 5px;
  box-shadow: var(--shadow);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  position: relative;
}

.board_card {
  cursor: pointer;
  background-color: var(--bg-panel);
  height: 9rem;
  // margin: 0.25rem;
  margin: 0.5rem 0.5rem 0.25rem 0;
  border-radius: 10px;
  // box-shadow: var(--shadow-button);
  display: flex;
  justify-content: space-between;
  // padding: 0.5rem;
  position: relative;
}

.grid_card_profile_img {
  width: 5rem;
  border-radius: 50%;
}

.grid_card_profile_img_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  img {
    height: 85%;
  }
}

.grid_card_information_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 60%;
  color: var(--color-text);
}

.grid_card_information {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid var(--border-color);
  padding: 1rem 0 0.5rem 0;

  // background-color: red;
  span {
    margin-top: 0.15rem;
  }
}

.grid_card_settings {
  display: flex;
  padding: 0.5rem 0 0 0;
  justify-content: space-around;
  color: var(--color-text);
  font-size: 0.75rem;

  label {
    left: -0.4rem;
  }

  svg {
    width: 1rem;
  }

  div {
    display: flex;
    width: 100%;
    height: 2rem;
    justify-content: space-around;
    align-items: center;
  }
}

.grid_card_name {
  font-weight: 600;
  font-size: 0.75rem;
}

.grid_card_phone {
  font-weight: 300;
  font-size: 0.5rem;
  width: 95%;
}

.grid_card_email {
  font-weight: 300;
  font-size: 0.5rem;
}

.grid_card_code {
  position: absolute;
  bottom: 0.25rem;
  left: 1rem;
}

.grid_card_position {
  font-size: 0.5rem;
  display: flex;
  flex-direction: row;

  .priority {
    padding: 0rem 0.4rem !important;
    border-radius: 0.25rem !important;
    font-size: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}

.badge {
  padding: 0 0.25rem 0.1rem 0;
  // background-color: #ff0;
  border-radius: 0.25rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background-color: var(--bg-modal);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: fixed;
  max-width: 95%;
  // max-height: 75%;
  border-radius: 0.5rem;
  background-color: var(--bg);

  .btn-close {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    background-color: white;
    box-shadow: $shadow;
    padding: 0.5rem;
    border-radius: 50%;
  }

  .btn-close2 {
    position: absolute;
    top: -1.5rem;
    right: -1.2rem;
    background-color: white;
    box-shadow: $shadow;
    padding: 0.5rem;
    border-radius: 50%;
  }
}

.modal_add_employee {
  right: 1rem;
  padding: 2rem 0.5rem 2rem 0.5rem;
  max-width: 30%;
}

.term_of_use_detail {
  padding: 2rem 0.5rem 2rem 0.5rem;
  max-width: 65%;
}

.modal_all_notifications {
  max-height: 30%;
  max-width: 30%;
}

.modal_add_leave {
  right: 1rem;
  padding: 2rem 0.5rem 2rem 0.5rem;
  max-width: 30%;
}

.leaveForm {
  padding: 0.5rem;

  .input-container {
    margin-bottom: 1rem;
  }

  .input_header {
    font-size: $fs-sm;
    margin-bottom: 0.25rem;
    color: var(--color-light);
  }

  .input {
    margin-left: unset;
    height: 2.5rem;
    box-shadow: unset;
    border: 1px solid var(--border-color);
  }

  .btn {
    width: 100%;
  }

  .select {
    padding-left: 1rem;
    color: var(--color-text);
  }
}

.employeeForm {
  .btn {
    float: right;
  }

  position: relative;
  overflow-y: auto;
  height: 550px;
  padding: 0.5rem 0.5rem 2rem 0.5rem;

  .select {
    padding-left: 1rem;
    color: var(--color-text);
  }

  .profile_list_img {
    width: 30%;
    margin: 0;
    padding: 0;
  }

  min-width: 20rem;
  // display: flex;
  // justify-content: flex-start;
  // flex-direction: column;
  min-height: 20rem;

  .input {
    margin-left: unset;
    height: 2.5rem;
    box-shadow: unset;
    border: 1px solid var(--border-color);
  }

  .textarea {
    margin-left: unset;
    height: 2.5rem;
    box-shadow: unset;

    textarea {
      width: 100%;
      border: 1px solid var(--border-color);
      border-radius: 0.5rem;
      padding: 1rem;
      color: var(--color-light);
    }

    margin-bottom: 3rem;
    // border: 1px solid var(--border-color);
  }

  .input-container {
    margin-bottom: 1rem;
  }

  .input_header {
    font-size: $fs-sm;
    margin-bottom: 0.25rem;
    color: var(--color-light);
  }

  .gender {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;

    svg {
      width: 0.5rem;
    }
  }
}

.dark {
  background-color: var(--primary);
  color: #fff;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 0.5rem;
  }

  label {
    margin-left: -1rem;
  }

  .btn-primary {
    padding: 0.25rem;
  }

  img {
    width: 5rem !important;
    height: 5rem;
  }
}

.scroll {
  width: 200px;
  height: 400px;
  background: red;
  overflow: scroll;
}

.modal_delete {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .btn {
    margin-top: 0.5rem;
  }

  color: var(--color-text);
}

.notification_information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  .title {
    font-weight: 600;
    color: var(--color-light);
  }
  .time {
    font-size: 0.5rem;
    color: #808495;
  }
}
.notification_clear {
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  // font-weight: bold;
  color: var(--primary);
  font-size: 0.75rem;
  cursor: pointer;
}
.type {
  font-size: 0.75rem;
  color: white;
  border-radius: 5px;
  padding: 0 5px 0 5px;
  right: 10rem;
}
.notification_container {
  width: 20rem;
  height: auto;
  // background-color: var(--bg);
  background: var(--bg-panel);
  box-shadow: var(--shadow);
  border-radius: 0.25rem;
  position: fixed;
  right: 9rem;
  top: 4rem;
  z-index: 999;
  padding: 1rem;

  .type {
    position: fixed;
  }

  h3 {
    font-size: $fs-sm;
    padding-left: 1rem;
    color: var(--color-text);
    // padding-bottom: 0.75rem;
    // border-bottom: 1px solid var(--border-color);
  }

  ul {
    list-style-type: none;
    border-top: 1px solid var(--border-color);
  }

  li {
    border-bottom: 1px solid var(--border-color);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    color: var(--color-text);
    height: 4rem;
    cursor: pointer;
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    img {
      border-radius: 50%;
      position: relative;
      width: 2rem;
      height: 2rem;
      float: left;
      margin-right: 0.5rem;
    }
    div:first {
      width: calc(100% - 2.5);
    }
    display: flex;
    align-items: center;
  }

  .seeall {
    display: flex;
    justify-content: center;
    // padding-top: 1rem;
    margin-bottom: 0.2rem;
    color: var(--primary);
    font-size: 0.75rem;
    cursor: pointer;
  }
}

.profile_menu {
  width: 15rem;
  height: auto;
  background-color: var(--bg-panel);
  color: var(--color-light);
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  position: fixed;
  right: 2rem;
  top: 5rem;
  z-index: 999;
  padding: 0.5rem 1rem 1rem 1rem;

  ul {
    list-style-type: none;
  }

  li {
    border-bottom: 1px solid var(--border-color);
    padding: 0.5rem;
    // text-align: center;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    // label {
    //   float: right;
    //   margin-top: .25rem;
    // }
  }

  li:last-child {
    border-bottom: unset;
  }

  .licolor {
    color: #4bc139;
  }
}

.multiselect {
  box-shadow: unset;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  // display: flex;
  input {
    padding: 0.75rem;
    width: 100%;
  }

  ul {
    list-style-type: none;
    position: absolute;
    margin-top: 2.5rem;
    background-color: #fff;
    // padding: 1rem;
    box-shadow: var(--shadow-button);
    border-radius: 0.5rem;
    z-index: 1;
  }

  li {
    border-bottom: 1px solid var(--border-color);
    padding: 0.5rem;
    cursor: pointer;
  }

  .selected_list {
    padding: 0.25rem;
    width: 100%;

    span {
      float: left;
      margin: 0.25rem;
      font-size: 0.5rem;
      padding: 0.25rem;
      cursor: pointer;
    }
  }
}

.grid_card_edit_buttons {
  // width: calc((100% - 7rem) / 3);
  width: 100%;
  height: 2rem;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  svg {
    width: 1rem;
    margin-right: 1rem;
  }

  padding-right: 0.5rem;
}

.bar_grid {
  display: flex;
  justify-content: flex-end;

  svg {
    margin-left: 1rem;
    width: 2rem;
  }
}

.employee_detail_container {
  width: 100%;
  height: 83vh;
  display: flex;

  .input-container {
    margin-bottom: 1rem;
  }

  .select {
    padding-left: 1rem;
    color: var(--color-text);
  }

  textarea {
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    padding: 1rem;
    color: var(--color-text);
  }
}

.projectProfile {
  justify-content: flex-start !important;
}

.employee_detail_profile {
  // background-color: green;
  width: 25%;
  // height: 100;
  display: flex;
  padding: 2rem 0 0 0;
  // margin-left: 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .profile_img {
    border-radius: 50%;
    width: 35%;
  }

  .employee_detail_items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-light);

    span {
      font-weight: lighter;
      font-size: 0.75rem;
      margin-top: 0.3rem;
    }

    .project-state {
      color: var(--color-text);
      padding: 0.25rem 0.4rem;
      border-radius: 5px;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
}

.employee_detail_right {
  // background-color: yellow;
  width: 75%;
  padding: 1rem 0 1rem 0;

  .inside_menu {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    color: var(--color-light);

    li {
      padding: 0 0 0.5rem 0;
      cursor: pointer;
    }

    .active {
      color: var(--color-text);
      border-bottom: 3px solid var(--primary);
      margin-bottom: -0.2rem;
    }
  }
}

.employee_detail_task {
  width: 100%;
  background-color: var(--bg-panel);
  border-radius: 5px;
  box-shadow: var(--shadow);
  text-align: center;
  position: relative;
  padding: 1rem;

  h4 {
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    li {
      min-height: 4rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  .date {
    color: var(--color-text);
    font-size: 0.75rem;
    position: absolute;
    left: 60%;
    display: inline-block;
    // right: 5%;
  }

  .color {
    min-height: 3rem;
    min-width: 0.5rem;
    position: absolute;
    left: 50%;
  }

  .desc {
    position: absolute;
    right: 55%;
    left: 20%;
    color: var(--color-light);
  }
}
.description_placeholder {
  height: 80%;
}
.employee_detail_content {
  // width: calc(100% - 1rem);
  height: calc(100%);
  margin: 0 0 0 0;
  // background-color: var(--bg-panel);
  box-shadow: var(--shadow);
  border-radius: 5px;

  .leave {
    padding: 1rem 1rem 0 1rem;
  }

  .overtime {
    padding: 1rem 1rem 0 1rem;
  }

  .debit {
    padding: 1rem 1rem 0 1rem;
  }

  .payroll {
    padding: 1rem 1rem 0 1rem;
  }

  .employee_document {
    padding: 1rem 1rem 0 1rem;
  }

  .personal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    .input {
      margin-left: unset;
    }

    .gender {
      display: flex;
      justify-content: space-between;

      svg {
        height: 1rem;
      }

      button {
        margin-left: unset;
      }
    }
  }
}
.__react_component_tooltip {
  visibility: unset !important;
  left: unset !important;
  top: unset !important;
  margin-left: -8rem;
  margin-top: -5rem !important;
  opacity: 1 !important;
}
.employee_detail_content_left {
  padding: 2rem 3rem 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .input_header {
    color: var(--color-light);
  }
}

.employee_detail_content_right {
  padding: 2rem 3rem 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--color-light);
  textarea {
    height: 60vh;
  }
}

.meeting_detail_content_right {
  padding: 2rem 3rem 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--color-light);
}

.input_danger_message {
  font-size: 0.5rem;
  color: #f00;
}

.avatar_max_size {
  color: red;
  font-size: 0.75rem;
  text-align: center;
  margin-top: 0.2rem;
  // position: absolute;
}

.file_uploader {
  width: 100%;
  height: 10rem;
  background-color: var(--file-upload-area);
  border-radius: 5px;
  margin-bottom: 1rem;
  color: var(--color-text);
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.background_image_uploader {
  display: flex;
  position: fixed;
  width: 17rem;
  background-color: var(--bg-panel);
  padding: 0.3rem 1rem;
  border-radius: 5px;
  opacity: 0.9;
  right: 1.5rem;
  top: 6rem;
  label {
    width: 100%;
  }
  svg {
    flex: 1;
  }
  .changeBackgroundImagetext {
    padding-left: 1rem;
    flex: 10;
    font-weight: 900;
    color: var(--color-text);
  }
}

.max_size {
  width: 100%;
  color: red !important;
  font-size: 0.75rem;
  text-align: center;
  margin-bottom: 1rem;
}

.file_upload_area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--color-text);

  .size {
    font-size: 0.5rem;
    color: var(--color-light);
  }
}

.select-box {
  padding: 1rem;
  z-index: 10;
  width: 100%;
  justify-content: center;
}

.select-box__default {
  font-size: $fs-sm;
  font-weight: 600;
}

.select-box__default__open {
  font-weight: 800;

  svg {
    transform: rotate(180deg);
  }
}

.select-box__options {
  position: absolute;
  background-color: var(--bg);
  box-shadow: $shadow;
  padding: 1rem 1rem;
  border-radius: 12px;
}

.select-box__option {
  justify-content: left !important;
  width: 100%;
  font-weight: 500;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  svg {
    width: 1rem;
    margin-right: 1rem;
  }
}

.select-box__option__selected {
  font-weight: bold;

  svg {
    fill: $primary;
  }
}

.leave_detail_container {
  width: 100%;
  // background-color: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0 0 0;
}

.inout_detail_box {
  background-color: var(--bg-panel);
  width: 8rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: var(--shadow-button);
  color: var(--color-text);
  margin-right: 1rem;
}

.leave_detail_box {
  background-color: var(--bg-panel);
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
  box-shadow: var(--shadow-button);
  color: var(--color-text);
  margin-right: 1rem;
}

.leave_detail_box_number {
  font-size: 2rem;
}

.leave_detail_box_text {
  font-size: 0.45rem;
  text-align: center;
  margin-bottom: 0.25rem;
}

.custemer_detail_container {
  box-shadow: var(--shadow);
  border-radius: 5px;
  width: 100%;
  height: 120vh;
  padding: 0 2rem 2rem 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .select {
    padding-left: 1rem;
    color: var(--color-text);
  }

  textarea {
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    padding: 1rem;
    color: var(--color-text);
  }
}

.customer_detail_profile {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 1rem;
  // background-color: red;
}

.customer_detail_profile_information {
  // background-color: yellow;
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 0.25rem;
    color: var(--color-text);
  }

  span:nth-child(1) {
    font-weight: bold;
    font-size: 1.2rem;
  }

  span:nth-child(3) {
    font-size: 0.8rem;
  }
}

.customer_detail_information {
  width: 50%;
  height: 100%;
  float: left;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.customer_detail_information_container {
  width: 100%;
  height: 60%;

  .btn {
    // margin-top: 3rem;
    width: 50%;
    align-self: flex-end;
  }

  .input {
    margin-left: unset;
  }
}

.errorspan {
  color: red;
  padding: 1rem;
  width: 100%;
  text-align: center;
}

.react-datepicker-wrapper {
  width: 100%;
  padding: 0.8rem;
}

.react-datepicker__input-container {
  width: 100%;
  margin-top: -0.2rem;
}

.missing_document {
  width: 100%;
  padding: 1rem;
  color: var(--color-text);
}

.missing_document_item {
  padding: 0.25rem;
  // margin-right: 0.5rem;
  border-radius: 0.5rem;
  color: $wrong-pink;
}

.selectfilter {
  width: 10rem;
  padding-left: 1rem;
  color: var(--color-text);
}

.responsiple_container {
  width: 100%;
  border-top: 1px solid var(--border-color);
  margin-top: 2rem;
  padding-top: 1rem;

  .input {
    margin-left: unset;
  }

  .btn {
    box-shadow: var(--shadow-button);
  }
}

.responsiple_container_header {
  width: 100%;
  text-align: center;
  color: var(--color-text);
  font-size: 1.5rem;
}

.left_sub_menu {
  color: var(--color-light);
  font-size: $fs-xxsm;
  width: 100%;
  padding-left: 1.5rem;
  // padding-left: .3rem;
  // margin-left: 2.75rem;
  li:hover {
    background-color: unset;
    border: unset;
    color: var(--primary);
  }
}

.left_sub_menu_item {
  border: unset;
  background-color: unset;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  height: 1rem;
}

.left_sub_menu_item_active {
  color: var(--primary);
}

.circleactive {
  background-color: var(--primary) !important;
}

.circle {
  background-color: var(--color-light_2);
  border-radius: 50%;
  width: 0.25rem;
  height: 0.25rem;
  // margin-left: 1rem;
  margin-right: 1.4rem;
}

.left_sub_menu_when_close {
  position: absolute;
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--bg-panel);
  left: 4.5rem;
  z-index: 10;
  box-shadow: var(--shadow);
  border-radius: 0.25rem;
  padding: 0.5rem 0.3rem 0.5rem 0.75rem;
}

// .mediumSwitch {
//   width: 56px !important;
//   height: 32px !important;
// }

// .mediumSwitch:after {
//   width: 20px !important;
//   height: 20px !important;
//   top: 6px !important;
//   left: 28px !important;
// }
.authModal {
  position: relative;
  overflow-y: auto;
  height: 90%;
  padding: 2rem;
  width: 97%;
  margin-top: 2rem;

  .btn {
    float: right;
  }
}

.authModalContainer {
  width: 50%;
  height: 80%;
}

.authModalRow {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;

  .input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.authModalSubRow {
  .input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  margin: 1rem 0 0 1rem;
  margin-left: 5rem;
}

.project_container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.project_group {
  min-width: 20%;
  color: var(--color-text);
  padding-top: 0.75rem;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .project_group_container {
    border-radius: 0.5rem;
    box-shadow: var(--shadow);
    margin-right: 1rem;
    // margin-top: 3.25rem;
    padding: 1rem 0 1rem 1rem;
  }

  ul {
    list-style-type: none;

    li:not(.active) {
      opacity: 0.8;
    }

    .active {
      color: var(--primary);
    }
  }

  li {
    cursor: pointer;
    padding: 0.5rem 0 0 0;
  }

  li:hover {
    color: var(--primary);
  }
}

.project_view {
  min-width: 80%;
}

.statuscircle {
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 1rem;
}

.grid_card_border {
  border-left: 0.5rem solid #000;
}

// .epnGRF {
//   // height: 70vh !important;
//   background-color: var(--bg) !important;
//   // justify-content: center;
//   margin-top: 2.5rem;
// }
.smooth-dnd-container.horizontal {
  white-space: unset !important;
  display: flex;
}

.smooth-dnd-container {
  height: 100%;
  min-height: 70vh;
}

.react-trello-lane {
  // background-color: var(--bg) !important;
  box-shadow: var(--shadow);
  border-radius: 2rem !important;
  width: 90%;
  border-top: 1rem solid #000;
  min-height: 5vh;
  color: var(--color-text);
  background-color: var(--bg) !important;
}

.react-trello-board {
  background-color: var(--bg) !important;
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
}
.react-trello-board > div {
  width: 100%;
}
#projectDetailProfile {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  box-shadow: var(--shadow-button);
}

.event_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;

  .btn {
    height: 2rem;
  }
}

.event_list {
  width: 100%;
  margin-bottom: 1rem;
}

.event_row {
  display: flex;
  justify-content: flex-start;

  button {
    svg:first-child {
      margin-right: 0.3rem;
    }

    svg {
      width: 1rem;
    }
  }
}

.event_profile {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  color: var(--color-light);

  img {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
  }
}

.event_desc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);

  span:nth-child(1) {
    color: var(--color-light);
    font-size: x-small;
  }

  span:nth-child(2) {
    color: var(--color-text);
    font-size: large;
  }

  span:nth-child(3) {
    color: var(--color-text);
    font-size: small;
  }
}

.grop_container_buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  .add_group {
    box-shadow: var(--shadow-button);
    font-size: $fs-sm;
    border-radius: 50%;
    padding: 0.7rem;
  }

  .edit svg {
    width: 25px;
    cursor: pointer;
  }

  .flex {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.meeting_group_container {
  padding: 1rem !important;
  border-radius: 0.5rem;
  box-shadow: var(--shadow);
  margin-right: 1rem;

  .edit svg,
  .trash svg {
    width: 17px !important;
    cursor: pointer;
  }

  ul {
    list-style-type: none;

    .active {
      color: var(--primary);
    }
  }

  li {
    cursor: pointer;
    align-items: center;
    padding: 0rem 0.2rem 0rem 0rem !important;
  }

  li:hover {
    color: var(--primary);

    .hide {
      visibility: visible !important;
    }
  }

  .hide {
    visibility: hidden;
  }

  .input {
    margin: 0 0 0.5rem 0 !important;
  }
}

.d-flex {
  display: flex !important;
  justify-content: space-between !important;
}

.edit_group_line {
  .btn,
  .trash {
    box-shadow: var(--shadow-button);
    padding: 0 0.5rem;
    cursor: pointer;
  }

  .input {
    margin: 0 0.4rem 0 0 !important;
  }

  span {
    font-size: 0.7rem;
  }

  .input-container {
    margin: 0 !important;
  }
}

.edit_group span {
  font-size: 0.8rem;
}

.add_grop_form {
  margin-bottom: 0.7rem;
  width: 100%;

  .input {
    margin: 0 !important;
  }

  .btn-group {
    box-shadow: var(--shadow-button);
    margin-top: 0.5rem;
  }

  span {
    font-size: 0.8rem;
  }
}

.meeting_users_img {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  float: left;
  box-shadow: var(--shadow-button);
}

.meetingForm {
  .btn {
    float: right;
  }

  position: relative;
  overflow-y: scroll;
  height: 500px;
  padding: 0.5rem 0.5rem 2rem 0.5rem;

  .select {
    padding-left: 1rem;
    color: var(--color-text);
  }

  .profile_list_img {
    width: 30%;
    margin: 0;
    padding: 0;
  }

  min-width: 22rem;
  min-height: 20rem;

  .input {
    margin-left: unset;
    height: 2.5rem;
    box-shadow: unset;
    border: 1px solid var(--border-color);
  }

  .textarea {
    margin-left: unset;
    height: 2.5rem;
    box-shadow: unset;

    textarea {
      width: 100%;
      border: 1px solid var(--border-color);
      border-radius: 0.5rem;
      padding: 1rem;
      color: var(--color-light);
    }

    margin-bottom: 3rem;
  }

  .input-container {
    margin-bottom: 1rem;
  }

  .input_header {
    font-size: $fs-sm;
    margin-bottom: 0.25rem;
    color: var(--color-light);
  }

  .gender {
    display: flex;

    svg {
      width: 4.5rem;
    }

    .file_uploader {
      height: 8rem;
    }

    .form-group {
      width: 58%;
      margin-right: 0.5rem;
    }
  }
}

.grid_card_participants {
  display: flex;
  padding: 0.5rem;
  color: var(--color-text);
  font-size: 0.75rem;

  label {
    left: -0.4rem;
  }

  svg {
    width: 1rem;
  }
}

.workorder_view {
  width: 100%;
}

.workorder_row_profile {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  align-self: center;
  margin-right: 0.5rem;
  float: left;
}

.label_container {
  background-color: var(--primary);
  color: var(--color-text-reverse);
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  width: 100%;
}

.progress_container {
  background-color: var(--primary-bg-color);
  color: var(--color-text-reverse);
  width: 100%;
  height: 1rem;
  border-radius: 0.4rem;
}

.progress_bar {
  background-color: var(--primary);
  color: var(--color-text-reverse);
  height: 1rem;
  border-radius: 0.4rem;
  // padding-right: 0.5rem;
  text-align: right;
  font-size: 0.75rem;
  min-width: 1.5rem;
}

.priority {
  color: var(--color-text-reverse);
  height: 1.25rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.65rem;
  padding: 0.25rem 0.5rem;
}

.workorder_card_settings {
  display: flex;
  // padding: 0.5rem 0 0 0;
  justify-content: flex-start;
  color: var(--color-text);
  font-size: 0.75rem;

  div {
    display: flex;
    width: 100%;
    height: 2rem;
    justify-content: left;
    align-items: center;
  }
}

.meeting_group_list {
  max-height: 50rem;
  overflow-x: auto;
  // margin-top: 1rem;
}

.text-wrap {
  flex-wrap: wrap;
}

.d-none {
  display: none;
}

.new_item {
  margin: 0 !important;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.ml-2 {
  margin-left: 1rem;
}

.text-muted {
  color: var(--color-light_2);
}

.m-0 {
  margin: 0 !important;
}

.f-right {
  float: right;
}

.f-left {
  float: left;
}

.bg-gray-border-left {
  border-left-color: var(--color-light) !important;
}

.bg-sky-border-left {
  border-left-color: var(--color-sky) !important;
}

.bg-sky-blue {
  color: var(--color-sky);
}

.grid_card_meeting_information_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  color: var(--color-text);
}

.grid_card_meeting_information {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
  margin: 0.5rem;

  // background-color: red;
  span {
    margin-top: 0.15rem;
  }
}

.modaltable td {
  border: 1px solid black;
}

.informtermofuse {
  font-size: large;
  color: red;
}

.termofusemodal {
  overflow-y: auto;
  min-height: 20rem;
  height: 600px;
  padding: 3rem 2rem;
  table tbody {
    box-shadow: unset;
  }
}

.termofuseul {
  list-style: none;
  padding-left: 1rem;

  li::before {
    content: "\2022";
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.meeting_detail_profile {
  width: 25%;
  display: flex;
  padding: 2rem 0 0 0;
  // margin-top: 1rem;
  flex-direction: column;
  // align-items: center;

  h5 {
    padding: 0.5rem 0 0 0;
  }

  h3 {
    padding: 1rem 0 0 0;
  }

  .profile_img {
    border-radius: 50%;
    width: 35%;
  }

  .meeting_detail_items {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-light);

    &:not(:last-child) {
      align-items: center;
    }

    &:last-child {
      margin-top: 2rem;
    }

    table {
      margin-top: 0.3rem;
    }

    .meeting_detail_users tbody tr td {
      a {
        cursor: pointer;
      }

      svg {
        width: 1.3rem !important;
        margin-top: 4px;
      }
    }

    span {
      font-weight: lighter;
      font-size: 0.75rem;
    }

    .text-bold {
      font-weight: bold;
      padding-top: 0.5rem;
      font-size: 0.9rem;
    }

    .meetind_detail_users {
      padding: 0.5rem;
    }
  }
}

.meeting_detail_info {
  flex-direction: row;
  height: 100%;

  .input {
    margin-left: unset;
  }

  .gender {
    display: flex;
    justify-content: space-between;

    svg {
      height: 1rem;
    }

    button {
      margin-left: unset;
    }
  }

  .meeting_detail_header {
    padding: 1.3rem 3rem;

    a svg {
      width: 0.4rem;
      margin-right: 0.3rem;
    }
  }
}

.meeting_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.meeting_files {
  .field_2 svg {
    width: 1.2rem;
  }
}

.workorder_card_information_container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  color: var(--color-text);
  width: 100%;
  padding: 0 0 0rem 1rem;
}

.grid_label_container {
  background-color: var(--primary);
  color: var(--color-text-reverse);
  padding: 0.2rem 0.4rem;
  border-radius: 0.25rem;
  font-size: 0.5rem;
  margin-right: 0.5rem;
}

.grid_labelmeeting_container {
  background-color: var(--primary);
  color: var(--color-text-reverse);
  padding: 0.2rem 0.4rem;
  border-radius: 0.25rem;
  font-size: 0.5rem;
  margin-left: 5rem;
  margin-right: 5rem;
  text-align: center;
}

.grid_greenlabel_container {
  background-color: #28a745;
  color: var(--color-text-reverse);
  padding: 0.2rem 0.4rem;
  border-radius: 0.25rem;
  font-size: 0.5rem;
  margin-right: 0.5rem;
}

.workorder_grid_card_information {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid var(--border-color);
  min-height: 5rem;
  padding-bottom: 0.25rem;

  // background-color: red;
  span {
    margin-top: 0.15rem;
  }

  .progress_container {
    background-color: var(--primary-bg-color);
    color: var(--color-text-reverse);
    width: 100%;
    height: 0.75rem;
    border-radius: 0.4rem;
  }

  .progress_bar {
    background-color: var(--primary);
    color: var(--color-text-reverse);
    height: 0.75rem;
    border-radius: 0.4rem;
    padding-right: 0.5rem;
    text-align: right;
    font-size: 0.5rem;
    min-width: 1.5rem;
  }
}

.react-datepicker {
  display: flex !important;
}

.department_container {
  padding: 1rem !important;
  border-radius: 0.5rem;
  box-shadow: var(--shadow);
  margin-right: 1rem;

  .edit svg,
  .trash svg {
    width: 20px !important;
    cursor: pointer;
  }

  ul {
    list-style-type: none;

    .active {
      color: var(--primary);
    }
  }

  li {
    cursor: pointer;
    padding: 0.5rem 0 0 0;
  }

  li:hover {
    color: var(--primary);
  }

  .input {
    margin: 0rem !important;
  }
}

.employeePerformanceForm {
  .btn {
    float: right;
  }

  position: relative;
  height: 160px;
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  min-width: 20rem;
  min-height: 10rem;

  .input {
    margin-left: unset;
    height: 2.5rem;
    box-shadow: unset;
    border: 1px solid var(--border-color);
  }

  .input-container {
    margin-bottom: 1rem;
  }

  .input_header {
    font-size: $fs-sm;
    margin-bottom: 0.25rem;
    color: var(--color-light);
  }

  .select {
    padding-left: 1rem;
    color: var(--color-text);
  }
}

.bg-orange {
  background-color: rgb(235, 103, 9);
}

.bg-yellow {
  background-color: rgb(255, 205, 23);
}

.bg-success {
  background-color: rgb(62, 190, 96);
}

.bg-danger {
  background-color: rgb(255, 76, 82);
}

.d-inline-block {
  display: inline-block;
}

.info-icon svg {
  width: 15px !important;
  margin-left: 0.5rem;
}

.mix-width-120 {
  min-width: 120px;
}

.react-datepicker {
  display: flex !important;
}

.font-weight-normal {
  font-weight: normal;
}

.icon_container {
  margin-left: 0.5rem;
  vertical-align: middle;

  svg {
    margin: unset;
    width: 0.8rem !important;
    height: 1rem !important;
  }
}

.transform-180 svg {
  transform: rotateZ(180deg);
}

.filter_by_users_menu {
  padding: 1.2rem;
  min-width: 5rem;
  // height: auto;
  background-color: var(--bg);
  color: var(--color-light);
  box-shadow: var(--shadow);
  border-radius: 0.35rem;
  font-size: 0.75rem;
  // position: fixed;
  height: 22rem;
  overflow-y: auto;

  .input {
    margin-left: unset;
  }

  .input-container {
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: none;
    height: auto;
  }

  li {
    border-bottom: 1px solid var(--border-color);
    padding: 0.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    label {
      margin-right: 0.5rem;
    }
  }

  li:last-child {
    border-bottom: unset;
  }

  .licolor {
    color: #4bc139;
  }
}

.filter_by_label {
  padding: 1.2rem;
  min-width: 18rem;
  min-height: 10rem;
  height: 50vh;
  background-color: var(--bg);
  color: var(--color-light);
  box-shadow: var(--shadow);
  border-radius: 0.35rem;
  font-size: 0.75rem;
  // position: fixed;
  overflow-y: auto;

  .input {
    margin-left: unset;
  }

  .input-container {
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: none;
    height: auto;

    .label-title {
      border: unset;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      span {
        font-size: 0.9rem;
      }
    }

    .label-item-list {
      margin-top: 0.5rem;
    }
  }

  li:not(.label-title) {
    border-bottom: 1px solid var(--border-color);
    padding: 0.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    label {
      margin-right: 0.5rem;
    }
  }

  li:last-child {
    border-bottom: unset;
  }

  .licolor {
    color: #4bc139;
  }
}

.modal_filter {
  top: 10rem;
  left: 45%;
}

.rangeInput {
  z-index: 4;
  position: absolute;
  width: 100%;
  background-color: transparent;
  height: 0.5rem;
  border-radius: 10px;
  top: 0;
}

input[type="range"] {
  appearance: auto;
  display: block;
  width: 100%;
  margin: 0px;
  padding: 0px;
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-bg-color) !important;
}

.workorder_detail {
  .priority {
    min-width: 3rem;
    max-width: 5rem;
    height: unset !important;
  }

  width: 20%;
  display: flex;
  padding: 2rem 0 0 0;
  // margin-left: 1rem;
  flex-direction: column;
  justify-content: flex-start;

  // align-items: center;
  .workorder_detail_header {
    width: 100%;
    padding: 0 0.5rem 0.5rem 0;
    border-bottom: 1px solid var(--border-color);
    margin: 0.5rem 0 0.5rem 0;

    h3 {
      font-size: $fs-sm;
      text-align: left;
    }
  }

  .workorder_detail_desc {
    overflow-y: auto;
    color: var(--color-light);
    text-align: left;
    width: 100%;

    h4 {
      color: var(--color-light);
    }
  }
}

.workorder_detail_personals {
  margin: 0.2rem 0 0.5rem 0;

  img {
    width: 2.5rem;
    margin-right: 1rem;
    border-radius: 50%;
  }

  .label_container {
    margin-right: 0.2rem;
  }
}

.workorderdate {
  font-size: 0.75rem;
}

.workorder_detail_right {
  width: 40%;
  padding: 1rem 0 1rem 0;

  .inside_menu {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    color: var(--color-light);

    li {
      padding: 0 0 0.5rem 0;
      cursor: pointer;
    }

    .active {
      color: var(--color-text);
      border-bottom: 3px solid var(--primary);
    }
  }
}

.workorderprioritiy {
  margin-bottom: 0.5rem;
  display: block;
  max-width: 150px;
}

.workorder_settings {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 1rem;
    width: 1.5rem;
  }
}

.workorder_detail_edit {
  height: 75vh;
}

.timesbyuser_container {
  width: 100%;
}

.tablewithoutshdow {
  font-size: 0.75rem;
  padding: 0 1rem 0 1rem;
  border-bottom: 1px solid var(--border-color);
  overflow-x: auto;

  table tbody {
    box-shadow: unset !important;

    td {
      font-size: 0.75rem;
    }
  }

  .row {
    img {
      width: 2rem;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }
}

.commentsbyworkorder {
  padding: 0.5rem 1rem 0.5rem 2rem;
  width: 100%;
  color: var(--color-text);

  ul {
    list-style: none;
    margin-bottom: 0.5rem;
    height: 275px;
    overflow-y: auto;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .active {
      background-color: #ffffbb;
    }
  }

  .date {
    font-size: 0.5rem;
    color: var(--color-light);
  }

  .info {
    // margin-left: 2.5rem;
    height: 3.5rem;
    width: 100%;
  }

  img {
    width: 2rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    // position: fixed;
  }

  .comment {
    font-size: 0.75rem;
    width: 100%;
    padding: 0 0 0.5rem 0;
    // height: 3.5rem;
    // overflow-y: auto;
  }

  .comment_area {
    width: 100%;

    .btn-primary {
      margin-top: -1rem;
      float: right;
      z-index: 10;
      padding: 0.05;
    }
  }
}

.timerforuser {
  color: var(--color-light);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15rem;

  span {
    font-size: 3rem;
  }

  .timer {
    display: flex;
    flex-direction: row;
  }
}

.timer_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 4rem;
    height: 4rem;
  }
}

.usertimes_container {
  width: 100%;
  padding: 0 1rem 0 0;

  .table {
    height: 19rem;
    overflow-y: auto;
  }
}

.btnTerminate {
  box-shadow: var(--shadow-button);
  display: flex;
  flex-direction: row;
  padding: 0.25rem;
  border-radius: 0.5rem;

  .scheduler_container {
    width: 100%;
    //  background-color: var(--bg-panel);

    // .fc-scrollgrid-sync-table {
    //   height: 70vh !important;
    // }
  }
}

.rbc-row-content {
  z-index: unset !important;

  table {
    padding: 0 !important;
  }
}

.btnTerminate {
  box-shadow: var(--shadow-button);
  display: flex;
  flex-direction: row;
  padding: 0.25rem;
  border-radius: 0.5rem;
  color: var(--color-light);

  div {
    width: 1rem;
    height: 1rem;
    background-color: red;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    color: var(--color-light);
  }
}

.modal_scheduler {
  padding: 2rem 0.5rem 2rem 0.5rem;
  background-color: var(--bg);
  min-width: 40%;
}

.modal_gantt {
  padding: 2rem 0.5rem 2rem 0.5rem;
  background-color: var(--bg);
  min-width: 70%;
}

.login_container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: var(--bg);
}

.login_image {
  // background: url("https://etd-inc.com/media/zoo/images/project-management_ed81b350ba0c61bf17e89c51077ceedb.jpg")
  //   no-repeat;
  background-size: cover;
  background-position: center;
  flex: 2;
  position: relative;
  display: flex;
  align-items: center;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
  }
  img {
    opacity: 0.2;
    width: 100%;
  }
}

.login_form_inside {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: var(--bg-panel);
  padding: 3rem 2rem;
  position: fixed;
  z-index: 102;
  border-radius: 0.75rem;
  box-shadow: var(--shadow);
  right: 5rem;
  max-width: 25%;
  .input_danger_message {
    display: none;
  }
  input {
    color: var(--color-text) !important;
  }
}

.login_form {
  display: flex;
  justify-content: center;
  align-items: center;
  .input {
    margin-left: 0rem;
  }

  span {
    color: var(--primary);
    // text-align: right;
    font-size: 0.75rem;
    padding: 0.5rem 0.5rem 0 0;
    cursor: pointer;
  }

  button {
    box-shadow: var(--shadow);
    border-radius: 5px;
    padding: 0.75rem;
    margin: 0.5rem 0 0 0;
    background-color: var(--primary);
    color: var(--color-text-reverse);
  }

  h2 {
    color: var(--color-text);
    font-size: 2rem;
    text-align: left;
    // line-height: 1rem;
    font-weight: 300;
  }

  p {
    font-size: 1.2rem;
    // padding: 0 0 0.5rem 1rem;
    word-wrap: break-word;
    color: var(--color-light_2);
  }

  .input {
    box-shadow: unset;
    border: 0.1rem solid var(--border-color);
    height: 3rem;
    margin: 0.5rem 0.5rem 0.5rem 0rem;
  }
}

.overlay {
  background-color: #000;
  width: 100%;
  height: 100vh;
  opacity: 0.6;
  position: fixed;
  z-index: 100;
}

.login_title {
  position: fixed;
  left: 10rem;
  z-index: 101;
  // display: flex;
  h1 {
    color: var(--color-light_2);
    font-size: 5rem;
    opacity: 0.9;
    margin-bottom: unset !important;
  }

  p {
    opacity: 0.75;
    width: 80%;
    font-size: 2rem;
    color: var(--color-light);
  }
}

.anonymous_container {
  position: fixed;
  left: 30%;
  top: 40%;
  color: var(--color-light);
  h1 {
    font-size: 5rem;
    line-height: 5rem;
  }
  p {
    font-size: 2rem;
  }
  svg {
    width: 2rem;
    height: 2rem;
  }
  button {
    display: flex;
    align-items: center;
  }
}

.login_message {
  text-align: center;
  font-size: $fs-sm;
  padding: 1rem;
  color: var(--bg-danger);
}

.rbc-toolbar {
  color: var(--color-text);

  button {
    color: var(--color-text) !important;
    border-color: var(--border-color) !important;
  }

  button:active,
  .rbc-active {
    color: var(--color-text-reverse) !important;
    background-color: var(--primary) !important;
  }
}

.rbc-time-view {
  color: var(--color-text);
  border-color: var(--border-color) !important;
  border: unset !important;
}

.rbc-month-view {
  border-color: var(--border-color) !important;
  color: var(--color-text);
  border: unset !important;
}

.rbc-header {
  border-color: var(--border-color) !important;
  color: var(--color-text);
  border: unset !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  box-shadow: unset !important;
}

.rbc-day-bg {
  border-color: var(--border-color) !important;
  border-radius: 0.55rem;
  box-shadow: var(--shadow-agenda);
  margin: 0.1rem 0.1rem 0.1rem 0.1rem;
  border: unset !important;
  // height: 10rem;
}

.rbc-off-range-bg {
  background: var(--primary-bg-color) !important;
}

.rbc-month-row {
  border-color: var(--border-color) !important;
  border: unset !important;
}

.rbc-today {
  background-color: var(--secondary-bg-color) !important;
}

.rbc-timeslot-group {
  border-color: var(--border-color) !important;
  border: unset !important;
  min-height: 70px !important;
}
.rbc-date-cell {
  padding: 1rem !important;
  font-weight: 700;
}
.rbc-time-content > * + * > * {
  border-color: var(--border-color) !important;
}
.rbc-time-content {
  border-top: unset !important;
}

.rbc-time-header.rbc-overflowing {
  border-color: var(--border-color) !important;
  border: unset !important;
}

.rbc-time-header-content {
  border-color: var(--border-color) !important;
  border: unset !important;
}
.rbc-time-view .rbc-row {
  min-height: 1rem !important;
}

.rbc-day-slot .rbc-time-slot {
  border-color: var(--border-color) !important;
}

.ganttMode {
  list-style-type: none;
  display: flex;
  margin: 0.2rem 0 1rem 1rem;
  justify-content: flex-end;
  // border-radius: .25rem;

  li {
    // border-radius: .25rem;
    box-shadow: var(--shadow-button);
    padding: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    color: var(--color-text);
    background-color: var(--bg);
    font-size: 0.75rem;
    border-radius: 0.25rem;
  }

  li:hover {
    background-color: var(--primary);
    color: var(--color-text);
  }
}

.ganttContainer {
  padding: 1rem;
  // height: 60vh;
  display: flex;
}
.ganttMessage {
  display: block;
  padding: 0.5rem;
  margin-left: 10rem;
  color: var(--bg-danger);
}
.ganttBox {
  flex: 2;

  div:first-child {
    //  pointer-events: none;

    overflow: unset !important;
    overflow-x: auto !important;
    overflow-y: auto !important;
  }

  // border-radius: 5px;
  // border: 1px solid var(--border-color);
  // padding: 0.5rem 0.5rem 0.5rem 0rem;
  overflow-y: auto !important;
  overflow-x: auto !important;
  // border-radius: 5px;
  // border: 1px solid var(--border-color);
  // padding: .5rem;
  width: 100%;

  .popup-wrapper {
    display: none;
  }
}

.gantt .bar-progress {
  fill: var(--primary) !important;
}

.gantt .grid-header {
  fill: var(--bg) !important;
  stroke: var(--border-color) !important;
  stroke-width: 1 !important;
}

.gantt .lower-text {
  fill: var(--color-text) !important;
}

.gantt .grid-row {
  fill: var(--bg) !important;
}

.gantt .grid-row:nth-child(even) {
  fill: var(--bg-panel) !important;
}

.gantt .lower-text {
  fill: var(--color-text) !important;
}

.gantt .grid-row {
  fill: var(--bg) !important;
}

.gantt .grid-row:nth-child(even) {
  fill: var(--bg-panel) !important;
}

.gantt-container {
  width: 100%;

  svg {
    pointer-events: none;
  }
}

.gantt .tick {
  stroke: var(--border-color) !important;
}

.modal_add_scoring {
  //right: 1rem;
  padding: 2rem 0.5rem 2rem 0.5rem;
  max-width: 30%;
}

.employee-range {
  .input-range {
    width: 70% !important;

    .input-range__label--min,
    .input-range__label--max {
      display: none !important;
    }
  }
}

.average-score {
  text-align: center;

  label {
    color: var(--color-light) !important;
  }

  // .fc-scrollgrid-sync-table {
  //   height: 70vh !important;
  // }
  h1 {
    font-size: 3rem;
    font-weight: 500;
    color: var(--color-sky_2);
  }

  span {
    font-size: 0.8rem;
    color: var(--color-light);
  }
}

.info-line {
  margin-top: 0.3rem;
  display: flex;
  justify-content: space-between;

  span:last-child {
    color: var(--color-sky_2);
  }

  span {
    font-size: 0.9rem;
    color: var(--color-light);
  }
}

.totalScore {
  font-size: 2rem;
  font-weight: 500;
  color: var(--color-sky_2);
  margin-bottom: unset !important;
}

.staff-of-the-month {
  margin-top: 0.5rem;
  padding: 0rem;

  span {
    color: var(--color-light);
  }
}

.personal_score_img {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  box-shadow: var(--shadow-button);
  float: right;
}

.personal_task {
  color: var(--color-sky_3);
  background: var(--color-light_3);
  padding: 0.2rem 0.4rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.75rem !important;
  margin-right: 0.5rem !important;
}

.personal_score_badge {
  color: var(--color-sky_3);
  background: var(--color-light_3);
  padding: 0.2rem 0.4rem !important;
  border-radius: 0.25rem !important;
  font-size: 0.75rem !important;
  margin-right: 0.5rem !important;
}

.grid_card_personal_perfomance {
  font-size: 0.5rem;
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    padding: 0.3rem 0;
  }

  .total_point {
    font-size: 2.2rem;
    line-height: 1.7rem;
  }
}

.grid_card_information_score {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0 0.1rem 0;

  // span:not(:last-child) {
  //   margin-top: 0.15rem;
  //   margin-bottom: 0.2rem;
  // }

  .grid_card_name {
    font-size: $fs-sm;
  }

  .grid_card_personal_perfomance .average {
    font-size: 0.75rem;
    color: var(--color-light);
  }
}

.grid_card_information_container_score {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 60%;
  color: var(--color-text);
}

.grid_card_score_info {
  background-color: var(--bg-panel);
  height: 12rem;
  border-radius: 5px;
  box-shadow: var(--shadow);
  margin: 0.2rem -0.6rem -0.6rem -0.6rem;
}

.grid_card_score_body {
  display: flex;
  justify-content: space-between;
  padding-right: 0.3rem;
  position: relative;

  .grid_card_profile_img_container {
    padding: 0.5rem;
  }
}

.grid_card_score_top {
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding: 0 0.5rem;
    font-size: 1.1rem;
    display: block;
    text-align: center;
  }

  .year {
    color: var(--color-light_2);
    font-size: 0.9rem;
  }
}

.employe_score_container {
  padding: 1rem !important;
  margin-right: 1rem;

  .employe_info {
    display: flex;
    justify-content: center;
  }
}

.sub_info {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .personal_task {
    margin-top: 0.5rem;
  }

  .month {
    display: flex;
    align-items: center;
    margin-top: 0.9rem;
    padding: 0.5rem;

    span {
      padding: 0 0.4rem;
      font-size: 1.2rem;
    }
  }

  .points {
    padding: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      font-size: 0.9rem;
      color: var(--color-light_2);
    }

    .score {
      text-align: center;
      font-size: 3rem;
      font-weight: 500;
      color: var(--color-orange);
    }

    .sugges {
      color: var(--color-red) !important;
    }
  }
}

.gantt .row-line {
  stroke: none !important;
}

.bg-color-21 {
  background-color: var(--bg-back-21);
  color: var(--bg-21);
  font-size: 0.9rem;
  height: auto;
  padding: 0.1rem 0.8rem;
}

.bg-color-13 {
  background-color: var(--bg-back-13);
  color: var(--bg-13);
  font-size: 0.9rem;
  height: auto;
  padding: 0.1rem 0.8rem;
}

.bg-color-8 {
  background-color: var(--bg-back-8);
  color: var(--bg-8);
  font-size: 0.9rem;
  height: auto;
  padding: 0.1rem 0.8rem;
}

.bg-color-5 {
  background-color: var(--bg-back-5);
  color: var(--bg-5);
  font-size: 0.9rem;
  height: auto;
  padding: 0.1rem 0.8rem;
}

.bg-color-3 {
  background-color: var(--bg-back-3);
  color: var(--bg-3);
  font-size: 0.9rem;
  height: auto;
  padding: 0.1rem 0.8rem;
}

.bg-color-2 {
  background-color: var(--bg-back-2);
  color: var(--bg-2);
  font-size: 0.9rem;
  height: auto;
  padding: 0.1rem 0.8rem;
}

.bg-color-1 {
  background-color: var(--bg-back-1);
  color: var(--bg-1);
  font-size: 0.9rem;
  height: auto;
  padding: 0.1rem 0.8rem;
}

.h-4rem {
  height: 4rem !important;
}

.range-footer {
  display: flex;
  justify-content: space-between;
  color: var(--color-light_2);
  margin: 1px -5px 1px -3px;
}

.edit-range {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 290px;
  float: right;

  .edit-pan svg {
    width: 1.2rem !important;
  }

  .btn-close svg {
    width: 1.1rem !important;
  }

  button svg {
    width: 1rem !important;
  }

  .range-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.grid_card_container_scoring {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  color: var(--color-text);

  .tree_gant_container {
    display: flex;

    .rst__tree {
      min-width: 50%;
      // background-color: red;
    }
  }

  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    background-color: var(--color-text) !important;
  }

  .rst__moveHandle,
  .rst__loadingHandle {
    background: var(--color-light_2) !important;
    border: unset !important;
    box-shadow: unset !important;
  }

  .rst__rowContents {
    border: unset !important;
    -webkit-box-shadow: unset !important;
  }

  .rst__toolbarButton {
    button {
      margin-top: 0.35rem;
    }

    svg {
      width: 0.75rem;
    }
  }

  .rst__moveHandle {
    width: 1rem !important;
  }

  .rst__rowTitle {
    font-size: 0.5rem;
    color: var(--color-text);
    font-weight: 400 !important;
    height: 100% !important;
    display: flex;
    justify-content: center;

    input {
      font-size: 0.5rem !important;
      color: var(--color-text);
    }
  }

  .rst__rowContents {
    padding: 0.5rem 0 0.5rem 0;
    background-color: var(--bg-panel) !important;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem !important;
    min-width: 150px !important;
  }

  .rst__rowWrapper {
    padding: 0.15rem 0.15rem 0.15rem 0 !important;
  }

  .rst__row {
    height: 100% !important;
  }

  .rst__moveHandle,
  .rst__loadingHandle {
    width: 0.75rem !important;
    background-color: var(--primary) !important;
    border-radius: 0.2rem !important;
  }

  .rst__rowToolbar {
    flex-direction: row;
    align-items: center;
  }

  .rst__toolbarButton {
    margin-left: 0.2rem;
  }
}

//Profil
.profile_detail {
  width: 25%;
  display: flex;
  padding: 2rem 0 0 0;
  flex-direction: column;
  align-items: center;

  .profile_img {
    border-radius: 50%;
    width: 35%;
  }

  .profile_detail_items {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-light);
    margin-top: 1rem;

    &:not(table span) {
      font-weight: lighter;
      font-size: 0.75rem;
    }
  }
}

.working_hour_time {
  color: var(--color-light);
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  box-shadow: var(--shadow-bottom);
}

.week_name {
  color: var(--color-light);
  font-weight: 500;
  font-size: 0.9rem;
  margin-left: 0.8rem;
}

.react-timekeeper {
  position: absolute !important;
  z-index: 1;
  margin-top: 0.2rem;
}

.react-timekeeper__top-bar {
  background: var(--bg-panel) !important;
  border-bottom: 1px solid var(--border-color2) !important;
  padding: 0 16px !important;
  border-radius: unset !important;
  position: relative !important;

  span {
    font-weight: 500;
  }
}

.rst__rowToolbar {
  flex-direction: row;
  align-items: center;
}

.rst__toolbarButton {
  margin-left: 0.2rem;
}

.gantt .bar {
  .bar-wrapper:first-child {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
  }
}

.frappe_disable_container {
  width: 100%;
}

.project_step_container {
  .td_settings {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  margin: 0 0 0 0;
  color: var(--color-text);
  min-height: 200px;
  // background-color: var(--bg-panel);
  overflow-x: auto;
  // border-radius: 5px;
  flex: 4;
  .headerRow {
    svg {
      margin-right: unset !important;
    }
    button {
      float: left;
    }
    // display: flex;
  }
  .headerStep {
    td {
      padding: unset;
    }
    .input input {
      font-weight: bold;
      font-size: 1rem;
    }
    .input {
      border: unset;
    }
    svg {
      width: 1rem;
      margin-right: 1rem;
    }
  }
  .btn {
    box-shadow: var(--shadow-button) !important;
    border: 1px solid var(--border-color);
    padding: 0.5rem;
    border-radius: 15%;
    // background-color: var(--color-text-reverse);
  }

  svg {
    width: 1rem;
  }

  select {
    color: var(--color-text);
  }

  .input-container {
    margin-bottom: unset;
  }

  .input {
    margin-left: unset;
    background-color: unset !important;
    // border-radius: unset;
    box-shadow: unset;
    border: 1px solid var(--color-light_2);
  }

  .input input,
  .input textarea {
    padding: 0.3rem;
  }

  table {
    margin-top: 2.3rem;
    thead {
      font-size: 0.75rem;
      svg {
        width: unset;
        margin-left: unset;
      }
      td {
        height: 40px;
      }
    }

    tbody {
      font-size: 0.65rem;
      box-shadow: var(--shadow);
      tr {
        cursor: pointer;
      }
      tr:nth-child(even) {
        background-color: var(--bg-panel) !important;
      }

      td {
        white-space: nowrap;
        padding: 0 0.3rem;
        height: 38px;
        //  max-height: 30px;
      }
    }

    tbody {
      box-shadow: unset;
      border: unset;
      overflow: unset;
      border-radius: unset;

      button {
        margin-left: 1rem;
      }
    }
  }
}

.addButton {
  border-radius: 50%;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  box-shadow: var(--shadow-button);
}

.react-timekeeper__hand-circle-outer {
  fill: var(--primary) !important;
}

.react-timekeeper__clock-hand {
  stroke: var(--primary) !important;
}

.react-timekeeper__hand-circle-center {
  fill: var(--primary) !important;
}

.react-timekeeper__tb-minute-wrapper {
  width: 72px !important;
  text-align: right !important;
  position: relative !important;
  display: inline-block !important;
}

.css-1aty463-TopBar {
  font-size: 40px !important;
}

.react-timekeeper__tb-colon {
  font-size: 36px !important;
  color: var(--primary) !important;
}

.css-1vnxzrd-TopBar {
  font-size: 40px !important;
}

.react-timekeeper__tb-hour {
  font-size: 40px !important;
  color: var(--primary) !important;
}

.react-timekeeper__tb-minute {
  font-size: 40px !important;
  color: var(--primary) !important;
}

.react-timekeeper__clock-wrapper {
  background-color: var(--bg-panel) !important;

  svg {
    background-color: var(--bg-panel) !important;
  }
}

.css-ue2et3-DoneButton {
  background: var(--bg-panel) !important;
  color: var(--color-light) !important;
  border-top: 1px solid var(--border-color2) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 12px 0 !important;
  display: block;
  text-align: center;
  cursor: pointer;
}

.css-1ddlhy {
  display: inline-block !important;
  position: absolute !important;
  color: var(--color-light_2) !important;
  pointer-events: none !important;
  border-radius: 99px !important;
  width: 34px !important;
  height: 34px !important;
  text-align: center !important;
  line-height: 34px !important;
  z-index: 5 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.css-vusjv6 {
  display: inline-block !important;
  position: absolute !important;
  color: var(--color-light_2) !important;
  pointer-events: none !important;
  border-radius: 99px !important;
  width: 34px !important;
  height: 34px !important;
  text-align: center !important;
  line-height: 34px !important;
  z-index: 5 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.css-1xcgt4g {
  display: inline-block !important;
  position: absolute !important;
  color: var(--color-light_2) !important;
  pointer-events: none !important;
  border-radius: 99px !important;
  width: 34px !important;
  height: 34px !important;
  text-align: center !important;
  line-height: 34px !important;
  z-index: 5 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.css-gtnj8u-TimeDropdown {
  background-color: var(--bg-panel) !important;
  border-color: var(--border-color) !important;

  .css-1wpijgc {
    color: var(--color-light) !important;
    font-weight: 600 !important;
  }

  .css-1myjr9n {
    background-color: var(--bg-2) !important;
    color: var(--color-text) !important;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.working_hours_view {
  display: flex;
  align-items: flex-start !important;
  margin-top: 1.5rem !important;

  .label {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
  }
}

.conpany_working_hours_table {
  width: 100%;
  border-collapse: collapse;
  padding: 10rem;
  margin-bottom: 1rem;
  color: var(--color-text);

  td {
    height: 2.8rem;
    margin: 0;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    text-align: left;
  }

  .btn {
    box-shadow: var(--shadow-bottom);
  }
}

.bg-secondary {
  background-color: var(--bg-panel);
  color: var(--color-light);
}

.float-right {
  float: right;
}

.pano_container {
  width: 100%;
  height: 82vh;
  // background-color: red;
  margin-top: 1rem;
  background-size: cover;
  background-position: center;
}

// .hi{
//   font-size: 1rem !important;
// }
.pano_header {
  width: 100%;
  height: 10vh;
  display: flex;
  svg {
    width: 1.5rem;
  }
  input {
    padding: 0.5rem;
  }
  .input {
    margin-left: unset;
  }
  div:first-child {
    flex: 1;
    // background-color: var(--bg-transparent);
    // margin-left: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // padding-top: 1rem;

    span {
      width: 100%;
      // height: 100%;
      font-size: 1.75rem;
      font-weight: 700;
      color: #eee;
      display: flex;
    }

    span:last-child {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  div:last-child {
    flex: 1;
    // background-color: var(--bg-transparent);
    // margin-left: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;

    span {
      color: #eee;
    }

    span:first-child {
      font-size: 3.5rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    span:last-child {
      margin-left: 1rem;
      width: 100px;
      height: 100%;
      text-align: left;
      display: flex;
      align-items: center;
    }
  }
}

.pano_body {
  width: 100%;
  height: 72vh;
  // background-color: red;
  display: flex;

  .pano_body_left {
    // background-color: blue;
    flex: 3;
    display: flex;
    flex-direction: column;

    .pano_body_left_top {
      flex: 2;

      // background-color: grey;
      display: flex;
      justify-content: space-between;

      .pano_body_left_top_pano_panel:last-child {
        margin-right: 0;
      }

      .pano_body_left_top_pano_panel:first-child {
        margin-left: 0;
        padding-top: 1rem;
      }

      .pano_body_left_top_pano_panel {
        flex: 1;
        background-color: var(--bg-transparent);
        margin: 1rem 0 0 1rem;
        border-radius: 0.5rem;
        height: calc(100%-1rem);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 20vh;
        position: relative;
        span {
          font-weight: 500;
          font-size: 1.25rem;
          position: absolute;
          left: 1rem;
          top: 1rem;
          color: var(--color-light);
        }
        .table {
          width: 100%;
          margin-top: 2.5rem;
        }
      }
      .pano_body_left_top_pano_panel:nth-child(2) {
        background-color: unset;
        margin: 1rem 0 0 0;
      }
    }

    .pano_body_left_bottom {
      flex: 3;
      //  background-color: black;
      //display: flex;
      // max-height: 50%;
      //flex-flow: column wrap;

      // max-height: 30vh !important;
      // max-height: 40rem;

      .reports_group {
        // display: flex;
        // flex: 1;
        background-color: var(--bg-transparent);
        margin: 1rem 0 1rem 0;
        border-radius: 0.5rem;
        min-height: calc(100% - 2rem);
        max-height: calc(100% - 2rem);
        position: relative;
        padding: 3rem 0 0 0;
        span {
          font-size: 1.25rem;
          position: absolute;
          left: 1rem;
          top: 1rem;
          color: var(--color-light);
        }
        .reports_container {
          box-shadow: unset;
          padding: 0 1rem !important;
        }

        canvas {
          flex: 1;
          // width: 100% !important;
          width: 100% !important;
          // max-height: 100% !important;
          max-height: calc(40vh - 2rem) !important;
          margin: 1rem;
        }
      }
    }
  }

  .pano_body_right {
    // background-color: green;
    flex: 1;
    display: flex;
    flex-direction: column;

    .pano_body_right_top {
      flex: 2;
      // background-color: whitesmoke;
      background-color: var(--bg-transparent);
      // max-width: 33%;
      border-radius: 0.5rem;
      margin: 1rem 0 1rem 1rem;
      position: relative;
      padding-top: 2rem;
      .table {
        max-width: 100%;
      }
      span {
        font-weight: 500;
        font-size: 1.25rem;
        position: absolute;
        left: 1rem;
        top: 1rem;
        color: var(--color-light);
      }
    }

    .pano_body_right_bottom {
      margin: 0 0 1rem 1rem;
      background-color: var(--bg-transparent);
      flex: 1;
      border-radius: 0.5rem;
      position: relative;
      padding-top: 2.5rem;
      span {
        font-weight: 500;
        font-size: 1.25rem;
        position: absolute;
        left: 1rem;
        top: 1rem;
        color: var(--color-light);
      }
    }
  }
}
.number_row {
  width: 100%;
  height: 100%;
  // background-color: red;
  display: flex;
  // flex-direction: column;
  // margin: 0 1rem 0 1rem;
  span {
    // padding-left: 1rem;
    padding-left: 1rem;
    font-weight: 500 !important;
    color: var(--color-light);
  }
  div {
    display: flex;
    width: calc(100% - 1rem);
    height: calc(100% - 0.5rem);
    min-width: 10rem;
    margin: 0 0 0.5rem 1rem;
    background-color: var(--bg-transparent);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    number {
      font-weight: 500;
      font-size: 3.75rem;
    }
  }
}
.number_row:nth-child(2) {
  div {
    margin-top: 0.5rem;
    height: calc(100% - 0.5rem);
  }
}
.bell_animate {
  margin-right: 1rem;

  svg {
    margin: 0 !important;
  }

  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.projectStepForm {
  flex: 1;
  padding: 0 1rem 0 1rem;
  .input_header {
    font-size: $fs-sm;
    margin-bottom: 0.25rem;
    color: var(--color-light);
  }
  tbody {
    box-shadow: unset;
  }
  textarea {
    height: 200px;
  }
}

.project_step_form_right_side {
  border-radius: 0.5rem;
  flex: 2;
  color: var(--color-text);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    width: 2.5rem;
    border-radius: 50%;
  }

  svg {
    width: 1rem;
  }

  select {
    color: var(--color-text);
  }

  .input-container {
    margin-bottom: unset;
  }

  .input {
    margin-left: unset;
    background-color: unset !important;
    // border-radius: unset;
    box-shadow: unset;
    border: 1px solid var(--color-light_2);
  }

  .input input,
  .input textarea {
    padding: 0.75rem;
    min-width: 4rem;
  }

  table {
    font-size: 0.75rem;
    margin-top: 0.45rem;

    thead {
      svg {
        width: unset;
        margin-left: unset;
      }
    }

    tbody {
      tr {
        cursor: pointer;
      }

      tr:nth-child(even) {
        background-color: var(--bg) !important;
      }

      td {
        white-space: nowrap;
        height: unset;
        padding: 0.124rem 1rem 0.125rem 1rem;
      }

      th {
        height: 3.65rem;
      }
    }

    tbody {
      box-shadow: unset;
      border: unset;
      overflow: unset;
      border-radius: unset;

      button {
        margin-left: 1rem;
      }
    }
  }
}

.project_step_form_container {
  overflow-y: auto;
  display: flex;
}

.project_workorder_table_properties {
  width: 98% !important;
  .input-container {
    margin-top: 1rem;
  }
}

.request_form_inside {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: var(--bg-panel);
  padding: 2rem;
  position: relative;
  z-index: 100;
  border-radius: 0.75rem;
  box-shadow: var(--shadow);
  margin: auto;
  .termofuse {
    display: flex;
    justify-content: start;
    margin: 0.5rem;
    margin-left: 11px;
    span {
      margin-left: 0.25rem;
    }
  }
}

.project_workorder_table {
  padding: 13px !important;
}

.termsofuseSwitch {
  min-width: 32px;
}

.request_form_success {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: var(--bg-panel);
  padding: 2rem;
  position: relative;
  z-index: 100;
  border-radius: 0.75rem;
  box-shadow: var(--shadow);
  margin: auto;
  color: var(--color-light);
  align-items: center;

  h4 {
    margin-top: 1rem;
    font-weight: normal;
  }
}

.request_form {
  padding: 1rem;
  height: 100%;
  display: flex;

  // span:not(.speacialSpan) {
  //   color: var(--primary);
  //   text-align: right;
  //   font-size: 0.75rem;
  //   padding: 0.5rem 0.5rem 0 0;
  //   cursor: pointer;
  // }

  button {
    box-shadow: var(--shadow);
    border-radius: 0.4rem;
    padding: 0.75rem;
    margin: 1rem 0.5rem 0 0.5rem;
    background-color: var(--primary);
    color: var(--color-text-reverse);
  }

  h2 {
    color: var(--color-text);
    font-size: 2rem;
    text-align: left;
    padding: 0 0 0.5rem 1rem;
    font-weight: 300;
  }

  p {
    font-size: 0.75rem;
    padding: 0 0 0.5rem 1rem;
    color: var(--color-light_2);
  }

  .input {
    box-shadow: unset;
    border: 0.1rem solid var(--border-color);
    height: 3rem;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
}

.resourceassignment_header {
  font-size: 2rem;
  color: var(--color-light);
}

.notfound {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .iso404Content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    @media only screen and (max-width: 767px) {
      order: 2;
      margin-top: 20px;
      align-items: center;
      text-align: center;
      flex-direction: column;
    }

    h1 {
      font-size: 84px;
      font-weight: 700;
      //color: ${palette('secondary', 2)};
      line-height: 1;
      margin: 0 0 25px;
    }

    h3 {
      font-size: 24px;
      font-weight: 400;
      //color: ${palette('text', 1)};
      margin: 0 0 10px;
      line-height: 1.2;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      //color: ${palette('text', 3)};
      margin: 0 0 10px;
    }

    button {
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 0;
      font-weight: 500;
      text-align: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 0;
      white-space: nowrap;
      line-height: 1.5;
      padding: 0 30px;
      font-size: 13px;
      flex-shrink: 0;
      height: 36px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      color: #ffffff;
      border-radius: 18px;
      //background-color: ${palette('primary', 0)};
      //${transition()};

      a {
        width: 100%;
        height: 100%;
        color: #ffffff;
        text-decoration: none;
      }

      &:hover {
        background-color: #4ca4e0;

        a {
          text-decoration: none;
        }
      }

      &:focus {
        outline: 0;
        box-shadow: none;

        a {
          text-decoration: none;
        }
      }
    }
  }

  .iso404Artwork {
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-left: ${props =>
    //props['data-rtl'] === 'rtl' ? 'inherit' : '100px'};
    // margin-right: ${props =>
    //   props['data-rtl'] === 'rtl' ? '100px' : 'inherti'};
    height: 500px;

    @media only screen and (max-width: 767px) {
      // margin-left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
      // margin-right: ${props => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
    }

    img {
      max-height: 100%;
    }
  }
}

.workorder_usertimes {
  margin: 3%;
  //margin-left: -1rem;
  .input {
    margin-left: 0rem !important;
  }
}

.diaryForm {
  overflow-y: auto;
  display: flex;
  .btn {
    float: right;
  }
  .dateView {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
  }
  .diaryForm_Left {
    padding: 0 1rem 0 1rem;
    flex: 1;
  }
  .diaryForm_Right {
    flex: 1;
    padding: 0 1rem 0 1rem;
    .description {
      color: var(--color-light);
    }
  }
  .select {
    padding-left: 1rem;
    color: var(--color-text);
  }

  .profile_list_img {
    width: 30%;
    margin: 0;
    padding: 0;
  }

  .input {
    margin-left: unset;
    height: 2.5rem;
    box-shadow: unset;
    border: 1px solid var(--border-color);
  }

  .textarea {
    margin-left: unset;
    height: 2.5rem;
    box-shadow: unset;

    textarea {
      width: 100%;
      border: 1px solid var(--border-color);
      border-radius: 0.5rem;
      padding: 1rem;
      color: var(--color-light);
    }

    margin-bottom: 3rem;
    // border: 1px solid var(--border-color);
  }

  .input-container {
    margin-bottom: 1rem;
  }

  .input_header {
    font-size: $fs-sm;
    margin-bottom: 0.25rem;
    color: var(--color-light);
  }

  .gender {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;

    svg {
      width: 0.5rem;
    }
  }
}

//Slider
.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-size: 14px;
  transition: all 0.1s ease-in;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}

.rangeslider .rangeslider__handle-tooltip:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
}

.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #7cb342;
  border-radius: 10px;
  top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}

.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}

.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}

.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}

.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}

.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}

.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}

.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}

.rangeslider__labels {
  position: relative;
}

.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: "";
  width: 10px;
  height: 2px;
  background: #000;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  transform: translate3d(-50%, 0, 0);
}

.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
  height: 80vh !important;
  // width: 100% !important;
  width: 25%;
}

.compnayCultureForm {
  .btn {
    float: right;
  }

  position: relative;
  overflow-y: auto;
  // height: 385px;
  padding: 0.5rem;

  .select {
    padding-left: 1rem;
    color: var(--color-text);
  }

  .profile_list_img {
    width: 30%;
    margin: 0;
    padding: 0;
  }

  min-width: 20rem;
  // display: flex;
  // justify-content: flex-start;
  // flex-direction: column;
  min-height: 20rem;

  .input {
    margin-left: unset;
    height: 2.5rem;
    box-shadow: unset;
    border: 1px solid var(--border-color);
  }

  .textarea {
    margin-left: unset;
    height: 2.5rem;
    box-shadow: unset;

    textarea {
      width: 100%;
      border: 1px solid var(--border-color);
      border-radius: 0.5rem;
      padding: 1rem;
      color: var(--color-light);
    }

    margin-bottom: 3rem;
    // border: 1px solid var(--border-color);
  }

  .input-container {
    margin-bottom: 1rem;
  }

  .input_header {
    font-size: $fs-sm;
    margin-bottom: 0.25rem;
    color: var(--color-light);
  }

  .gender {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;

    svg {
      width: 0.5rem;
    }
  }
}

.ant-select-dropdown {
  z-index: 99999 !important;
}

input,
button,
select,
optgroup,
textarea {
  line-height: normal !important;
}

.error-message {
  color: var(--bg-danger);
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
}

.project_cost_container {
  width: calc(100%);
  height: calc(100%);
  display: flex;
  flex-direction: column;

  section {
    width: 100%;
    height: calc(100%);
  }

  section:nth-child(1) {
    flex: 1;
  }

  th {
    font-weight: 600;
  }
  section:nth-child(2) {
    flex: 12;
    display: flex;

    .project_cost_container_left_side {
      width: calc(100%);
      height: calc(100%);
      border-right: 1px solid var(--border-color2);
    }

    .project_cost_container_right_side {
      width: calc(100%);
      height: calc(100%);
    }
  }
}

.login_expired_modal {
  //margin-top: 6%;
  padding: 3%;
  display: flow-root;
  h1 {
    margin-top: 6%;
  }
  video {
    max-width: 50%;
  }
  // :not(h1,h2) {
  //   background: #ff0000;
  // }
  background-size: cover;
  background-position: center;
  flex: 2;
  position: relative;
  video {
    position: absolute;
    top: 50%;
    left: 46%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 90%;
    min-height: 90%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
  }
}

.project_cost_header_container {
  width: calc(100%);
  height: calc(100%);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  // background-color: peru;
  button {
    box-shadow: var(--shadow-button);
  }

  .headerspan {
    margin-right: -19rem;
    width: calc(52%);
  }
}

.switch_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  // margin-left: 1rem;
  color: var(--color-light);
  margin-right: 1rem;
  background-color: var(--bg-panel);
  padding: 0.4rem;
  border-radius: 0.5rem;

  span {
    margin-right: 0.75rem;
  }

  box-shadow: var(--shadow-button);
}

.ant-input-number {
  border: unset;
  width: 100% !important;
}

.ant-input-number-input {
  height: 2.4rem;
}
.loader_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  // background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-transparent);
  img {
    width: 5rem;
  }
}
.reports_container {
  // width: 100%;
  overflow-y: auto;

  table {
    width: 100%;
    td {
      text-align: center;
      padding: 0.3rem;
    }
  }
  thead {
    tr:nth-child(2) {
      th {
        border-right: unset;
      }
    }
    th {
      font-weight: bolder;
      text-align: center;
    }
  }
  tfoot {
    tr:nth-child(2) {
      th {
        border-right: unset;
      }
    }
    th {
      font-weight: bolder;
    }
  }
  tr {
    border: 1px solid var(--border-color);
    th:nth-child(2) {
      border-right: 1px solid var(--border-color);
      font-weight: bolder;
    }
    td:nth-child(2) {
      border-right: 1px solid var(--border-color);
    }

    td:hover {
      background-color: var(--secondary-bg-color);
    }
  }
  tbody {
    box-shadow: unset;
    border-radius: unset;
  }
  img {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
}

.kanban_continue_button {
  background-color: powderblue;
  margin-left: 9%;
  margin-top: -2%;
  z-index: 100;
  position: absolute;
}

.project_workorder_button {
  margin: 15px;
}

.customer_input {
  padding-top: 15px;
}

.customer_button {
  margin-top: 16px;
}

.customer_detail_content {
  // width: calc(100% - 1rem);
  height: calc(100%);
  margin: 0 0 0 0;
  // background-color: var(--bg-panel);
  box-shadow: var(--shadow);
  border-radius: 5px;

  .leave {
    padding: 1rem 1rem 0 1rem;
  }

  .overtime {
    padding: 1rem 1rem 0 1rem;
  }

  .debit {
    padding: 1rem 1rem 0 1rem;
  }

  .payroll {
    padding: 1rem 1rem 0 1rem;
  }

  .employee_document {
    padding: 1rem 1rem 0 1rem;
  }

  .personal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    .input {
      margin-left: unset;
    }

    .gender {
      display: flex;
      justify-content: space-between;

      svg {
        height: 1rem;
      }

      button {
        margin-left: unset;
      }
    }
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary) !important ;
  border-color: var(--primary) !important ;
}
.ant-notification {
  z-index: 10000 !important;
}
.ant-pagination-item:not(.ant-pagination-item-active) {
  background-color: var(--bg-color) !important;
  border: 1px solid var(--color-light_2) !important;
  color: var(--color-light_2);
  a {
    color: unset !important;
  }
}
.ant-pagination-item-link {
  background-color: var(--bg-color) !important;
  color: var(--color-light_2) !important;
}
.ant-pagination-item-active {
  background-color: var(--bg-color) !important;
}
.ant-pagination-disabled {
  font-family: unset !important;
  color: var(--color-light) !important;
  // border: 1px solid var(--color-light_2) !important;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 1px solid var(--color-light_2) !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--bg-panel) !important;
  border: 1px solid var(--color-light_2) !important;
  color: var(--color-light) !important;
}
.ant-select-dropdown {
  background-color: var(--bg-panel) !important;
  color: var(--color-light) !important;
}
.ant-select-multiple .ant-select-selection-item {
  background-color: var(--bg-panel) !important;
  color: var(--color-light) !important;
  border: 1px solid var(--color-light_2) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--bg-color) !important;
  color: var(--color-light) !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--bg-color) !important;
  color: var(--color-light) !important;
}

@for $i from 1 through 20 {
  .frappe_custom_bar:nth-child(#{$i}) {
    $mycolor: rgba(random(255), random(255), random(255), 1);
    // background-color: $mycolor !important;
    input {
      color: $mycolor !important;
    }
    .bar {
      fill: $mycolor !important;
    }
  }
}

.frappe_custom_bar {
  .bar {
    height: 5px !important;
    border-radius: 0px !important;
  }

  .bar-progress {
    display: none;
  }

  .bar-label {
    font-size: 8px !important;
  }
}
.bar-progress {
}

.shared_project_container {
  background-size: cover;
  background-position: center;
  position: fixed;
  display: flex;
 flex-direction: column;

  align-items: center;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  .backgroundVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
  }
  .backgroundGif {
    opacity: 0.1;
    width: 100%;
  }
}
.shared_project_detail_container{
 width: 100%;
 height: 180px;
 background-color: red;
 flex: 1;
 min-height: 100vh;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
@for $i from 1 through 20 {
  .shared_project_detail_container:nth-child(#{$i}) {
    $mycolor: rgba(random(255), random(255), random(255), 1);
    background-color: $mycolor !important;
  }
}